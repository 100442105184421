import React, { Component } from "react";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import { Card, Table, Button, message } from "antd";
import { CloudDownloadOutlined } from "@ant-design/icons";
import axios from "axios";
import URLs from "./utils/apis";
import { BASE_URL } from "./utils/consts";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

class Internship extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      tableloader: false,
    };
  }
  componentDidMount() {
    this.get_internship_list();
  }
  get_internship_list = () => {
    this.setState({ tableloader: true });
    axios
      .get(URLs.get_internship_list)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState({
            list: response.data.data,
            tableloader: false,
          });
        } else {
          this.setState({ tableloader: false });
        }
      })
      .catch((error) => {
        this.setState({ tableloader: false });
        console.error("Warning:", error);
      });
  };
  exportToCSV = () => {
    let arr = [];
    for (let item of this.state.list) {
      let photo_url = BASE_URL + "/" + item.photo;
      let resume_url = BASE_URL + "/" + item.resume;
      let obj = {
        "Full Name": item.name ? item.name : "---",
        Mobile: item.mobile ? item.mobile : "---",
        Email: item.email ? item.email : "---",
        "Intership For": item.intership_for ? item.intership_for : "---",
        "Current Location": item.current_location
          ? item.current_location
          : "---",
        "Photo URL": photo_url,
        "Resume URL": resume_url,
        "Date Applied": item.date_created,
      };
      arr = [...arr, obj];
    }
    let fileName = "Internship List";
    const ws = XLSX.utils.json_to_sheet(arr);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    message.success("Data exported successfully.");
  };
  downloadFile = (item) => {
    let url = BASE_URL + "/" + item.photo;
    const newTab = window.open(url, "_blank");
    newTab.focus();
  };
  downloadFileResume = (item) => {
    let url = BASE_URL + "/" + item.resume;
    const newTab = window.open(url, "_blank");
    newTab.focus();
  };
  render() {
    const dataSource =
      this.state.list &&
      this.state.list.map((item, index) => {
        return {
          key: index,
          sr_no: Number(index + 1),
          full_name: item.name ? item.name : "---",
          mobile: item.mobile ? item.mobile : "---",
          email: item.email ? item.email : "---",
          intership_for: item.intership_for ? item.intership_for : "---",
          current_location: item.location_name ? item.location_name : "---",
          photo: item,
          resume: item,
          date_created: item.created_at
            ? moment(item.created_at).format("DD-MM-YYYY")
            : "---",
        };
      });
    const columns = [
      {
        title: "Full Name",
        dataIndex: "full_name",
        key: "full_name",
      },
      {
        title: "Mobile",
        dataIndex: "mobile",
        key: "mobile",
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Internship For",
        dataIndex: "intership_for",
        key: "intership_for",
      },
      {
        title: "Current Location",
        dataIndex: "current_location",
        key: "current_location",
      },
      {
        title: "Date Applied",
        dataIndex: "date_created",
        key: "date_created",
      },
      {
        title: "Resume Download",
        dataIndex: "resume",
        key: "resume",
        render: (item) => {
          return (
            <div>
              {item.resume ? (
                <Button
                  type="primary"
                  onClick={() => this.downloadFileResume(item)}
                >
                  <CloudDownloadOutlined /> Download
                </Button>
              ) : (
                <p>Not Found</p>
              )}
            </div>
          );
        },
      },
      {
        title: "Photo Download",
        dataIndex: "photo",
        key: "photo",
        render: (item) => {
          return (
            <div>
              {item.resume ? (
                <Button type="primary" onClick={() => this.downloadFile(item)}>
                  <CloudDownloadOutlined /> Download
                </Button>
              ) : (
                <p>Not Found</p>
              )}
            </div>
          );
        },
      },
    ];
    return (
      <div>
        <Header />
        <div className="content-wrapper">
          <div className="d-flex justify-content-between">
            <Sidebar />
            <div className="main-wrapper">
              <div className="inner">
                <div className="sidebar-space"></div>
                <div className="main-content">
                  <div className="row">
                    <div className="col-lg-12">
                      <Card>
                        <Card>
                          <div className="d-flex justify-content-between">
                            <h3>
                              Internship List - (Total: {this.state.list.length}
                              )
                            </h3>
                            <Button
                              onClick={() => this.exportToCSV()}
                              type="primary"
                              icon={<CloudDownloadOutlined />}
                            >
                              Export
                            </Button>
                          </div>
                        </Card>
                        <br />
                        <Card>
                          <Table dataSource={dataSource} columns={columns} />
                        </Card>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Internship;
