import React, { Component } from "react";
import { Card, Button, Spin, message, Input } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import URLs from "../../utils/apis";
import axios from "axios";
import { BASE_URL } from "../../utils/consts";

const { TextArea } = Input;

export default class ImageData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: "",
      loading: false,
      img1_link: "",
      img2_link: "",
      img1: "",
      img2: "",
      img1_url: "",
      img2_url: "",
    };
  }
  componentDidMount() {
    this.get_header_data();
  }
  get_header_data = () => {
    this.setState({ loading: true });
    axios
      .get(URLs.get_header_data)
      .then((response) => {
        if (response.data.status == "1") {
          let img1_url = BASE_URL + "/" + response.data.data[0].img1;
          let img2_url = BASE_URL + "/" + response.data.data[0].img2;
          this.setState({
            img1_link: response.data.data[0].img1_link,
            img2_link: response.data.data[0].img2_link,
            img1_url: img1_url,
            img2_url: img2_url,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        // console.error("Warning:", error);
      });
  };
  onChangeImage1 = (e) => {
    console.log("img1: ", e.target.files);
    this.setState({
      img1: e.target.files[0],
    });
  };
  onChangeImage2 = (e) => {
    console.log("img2: ", e.target.files);
    this.setState({
      img2: e.target.files[0],
    });
  };
  update_data = () => {
    this.setState({ loading: true });
    let formdata = new FormData();
    formdata.append("img1", this.state.img1);
    formdata.append("img2", this.state.img2);
    formdata.append("img1_link", this.state.img1_link);
    formdata.append("img2_link", this.state.img2_link);
    formdata.append("type", "image_update");
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    axios
      .post(URLs.update_header_data, formdata, config)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState(
            {
              loading: false,
            },
            () => this.get_header_data()
          );
          message.success(response.data.message);
          console.log("response", response.data);
        } else {
          this.setState({ loading: false });
          message.warning(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        message.warning("Please try again.");
        console.error("Warning:", error);
      });
  };
  render() {
    return (
      <div>
        <Spin spinning={this.state.loading}>
          <Card className="ads-content-wrapper">
            <div className="mb-3">
              <label style={{ fontSize: 16, paddingBottom: 6 }}>
                Select Image
              </label>
              {this.state.img1_url && (
                <div>
                  <a href={this.state.img1_url} target="_blank">
                    <img
                      src={this.state.img1_url}
                      style={{
                        maxWidth: 100,
                        borderRadius: 5,
                        marginBottom: 13,
                        padding: 4,
                        border: "1px solid #ddd",
                      }}
                    />
                  </a>
                </div>
              )}
              <Input
                type="file"
                onChange={this.onChangeImage1}
                accept="image/*"
              />
            </div>
            <div className="mb-3">
              <label style={{ fontSize: 16, paddingBottom: 6 }}>
                Image 1 Link
              </label>
              <TextArea
                rows={2}
                onChange={(e) => this.setState({ img1_link: e.target.value })}
                value={this.state.img1_link}
              />
            </div>
            <div className="mb-3">
              <label style={{ fontSize: 16, paddingBottom: 6 }}>
                Select Image
              </label>
              {this.state.img2_url && (
                <div>
                  <a href={this.state.img2_url} target="_blank">
                    <img
                      src={this.state.img2_url}
                      style={{
                        maxWidth: 100,
                        borderRadius: 5,
                        marginBottom: 13,
                        padding: 4,
                        border: "1px solid #ddd",
                      }}
                    />
                  </a>
                </div>
              )}
              <Input
                type="file"
                onChange={this.onChangeImage2}
                accept="image/*"
              />
            </div>
            <div className="mb-3">
              <label style={{ fontSize: 16, paddingBottom: 6 }}>
                Image 2 Link
              </label>
              <TextArea
                rows={2}
                onChange={(e) => this.setState({ img2_link: e.target.value })}
                value={this.state.img2_link}
              />
            </div>
            <Button
              type="primary"
              icon={<LogoutOutlined />}
              onClick={() => this.update_data()}
            >
              Update
            </Button>
          </Card>
        </Spin>
      </div>
    );
  }
}
