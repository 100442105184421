import React, { Component } from "react";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import { Card, Input, Button, Spin, message } from "antd";
import axios from "axios";
import URLs from "./utils/apis";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formLoader: false,
      new_password: "",
      confirm_password: "",
      loading: false,
    };
  }
  // change password
  change_password = () => {
    if (!this.state.new_password) {
      message.warning("New password is required.", 2);
      return;
    }
    if (!this.state.confirm_password) {
      message.warning("Confirm password is required.", 2);
      return;
    }
    if (this.state.new_password != this.state.confirm_password) {
      message.warning("New and confirm password should match", 2);
      return;
    }
    let params = {
      new_password: this.state.new_password,
      confirm_password: this.state.confirm_password,
      admin_id: localStorage.getItem("admin_id"),
    };
    this.setState({ loading: true });
    axios
      .post(URLs.change_password, params)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState({
            loading: false,
            new_password: "",
            confirm_password: "",
          });
          message.success(response.data.message);
        } else {
          this.setState({ loading: false });
          message.warning(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        message.warning("Please try again.");
        console.error("Warning:", error);
      });
  };
  // on change input
  onchange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  render() {
    return (
      <div>
        <Header />
        <div className="content-wrapper">
          <div className="d-flex justify-content-between">
            <Sidebar />
            <div className="main-wrapper">
              <div className="inner">
                <div className="sidebar-space"></div>
                <div className="main-content">
                  <div className="col-lg-5">
                    <Spin spinning={this.state.loading}>
                      <Card>
                        <Card>
                          <div className="d-flex justify-content-between">
                            <h3>Change Password</h3>
                          </div>
                        </Card>
                        <br />
                        <Card>
                          <div className="col-lg-12">
                            <div class="form-group col-md-12 mb-3">
                              <label for="inputPassword4" className="mb-1">
                                New Password
                              </label>
                              <Input
                                type="password"
                                class="form-control"
                                name="new_password"
                                value={this.state.new_password}
                                onChange={this.onchange}
                              />
                            </div>
                            <div class="form-group col-md-12 mb-3">
                              <label for="inputPassword4" className="mb-1">
                                Confirm Password
                              </label>
                              <Input
                                type="password"
                                class="form-control"
                                name="confirm_password"
                                value={this.state.confirm_password}
                                onChange={this.onchange}
                              />
                            </div>
                            <Button
                              type="primary"
                              onClick={() => this.change_password()}
                            >
                              Change Password
                            </Button>
                          </div>
                        </Card>
                      </Card>
                    </Spin>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ChangePassword;
