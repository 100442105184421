import React, { Component } from "react";
import { Card, Button, Spin, message, Input } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import URLs from "../../utils/apis";
import axios from "axios";
import { BASE_URL } from "../../utils/consts";

const { TextArea } = Input;

export default class VideoData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: "",
      loading: false,
      video_file_name: "",
      video_link: "",
      video_url: "",
    };
  }
  componentDidMount() {
    this.get_header_data();
  }
  get_header_data = () => {
    this.setState({ loading: true });
    axios
      .get(URLs.get_header_data)
      .then((response) => {
        if (response.data.status == "1") {
          let video_file_name =
            BASE_URL + "/" + response.data.data[0].video_file_name;
          this.setState({
            video_url: video_file_name,
            video_link: response.data.data[0].video_link,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        // console.error("Warning:", error);
      });
  };
  onSelectVideoFile = (e) => {
    this.setState({
      video_file_name: e.target.files[0],
    });
  };
  update_data = () => {
    this.setState({ loading: true });
    let formdata = new FormData();
    formdata.append("video_file_name", this.state.video_file_name);
    formdata.append("video_link", this.state.video_link);
    formdata.append("type", "video");
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    axios
      .post(URLs.update_header_data, formdata, config)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState(
            {
              loading: false,
            },
            () => this.get_header_data()
          );
          message.success(response.data.message);
          console.log("response", response.data);
        } else {
          this.setState({ loading: false });
          message.warning(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        message.warning("Please try again.");
        // console.error("warning:", error);
      });
  };
  render() {
    return (
      <div className="mt-3">
        <Spin spinning={this.state.loading}>
          <Card className="ads-content-wrapper">
            <div className="mb-3">
              <label style={{ fontSize: 16, paddingBottom: 6 }}>
                Select Video
              </label>
              {this.state.video_url && (
                <div
                  style={{
                    padding: 10,
                    border: "1px solid #ddd",
                    borderRadius: 5,
                    width: "fit-content",
                    marginBottom: 10,
                  }}
                >
                  <a href={this.state.video_url} target="_blank">
                    <video width="300" height="170" controls>
                      <source src={this.state.video_url} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </a>
                </div>
              )}
              <Input
                type="file"
                onChange={this.onSelectVideoFile}
                accept="video/*"
              />
            </div>
            <div className="mb-3">
              <label style={{ fontSize: 16, paddingBottom: 6 }}>
                Video Link
              </label>
              <TextArea
                rows={2}
                onChange={(e) => this.setState({ video_link: e.target.value })}
                value={this.state.video_link}
              />
            </div>
            <Button
              type="primary"
              icon={<LogoutOutlined />}
              onClick={() => this.update_data()}
            >
              Update
            </Button>
          </Card>
        </Spin>
      </div>
    );
  }
}
