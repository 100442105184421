import React, { Component } from "react";
import { message, Spin, Modal, Button, Table } from "antd";
import axios from "axios";
import URLs from "../utils/apis";
import { BASE_URL } from "../utils/consts";
import { CloudDownloadOutlined } from "@ant-design/icons";
import moment from "moment";

export default class ViewAppliedJobs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      records: [],
      modalStatus: false,
      single: {},
      formLoader: false,

      no_of_posts: [],
      experience_list: [],
      ctc_list: [
        "0-3 Lakhs",
        "3-6 Lakhs",
        "6-10 Lakhs",
        "10-15 Lakhs",
        "15-25 Lakhs",
        "25-50 Lakhs",
        "50-75 Lakhs",
        "75-100 Lakhs",
      ],
      job_id: "",
      position: "",
      about_company: "",
      date_posted: "",
      no_of_post: 1,
      job_description: "",
      candidate_profile: "",
      experience: "",
      qualification: "",
      location_avaiable: "",
      ctc: "",
      department_id: "",
      company_name: "",
      industry_id: "",
      role_id: "",
      employment_type: "",

      department_list: [],
      industry_list: [],
      role_list: [],
      employmenttype_list: ["Full Time", "Part Time", "Permanent"],
      short_job_description: "",
    };
  }
  get_list = () => {
    let params = {
      job_id: this.props.data.job_id,
    };
    axios
      .post(URLs.get_applied_job_list, params)
      .then((response) => {
        if (response.data.status == "1") {
          console.log("response", response);
          this.setState({
            records: response.data.data,
          });
        }
      })
      .catch((error) => {
        this.setState({ formLoader: false });
        message.warning("Please try again.");
        console.error("Warning:", error);
      });
  };
  componentDidMount() {
    console.log("edit details", this.props);
  }
  show_modal = () => {
    let item = this.props.data;
    this.setState(
      {
        modalStatus: true,
      },
      () => this.get_list()
    );
  };
  update = () => {};
  closeModal = () => {
    this.setState({
      modalStatus: false,
    });
  };
  downloadFile = (item) => {
    let url = BASE_URL + "/" + item.photo;
    const newTab = window.open(url, "_blank");
    newTab.focus();
  };
  downloadFileResume = (item) => {
    let url = BASE_URL + "/" + item.resume;
    const newTab = window.open(url, "_blank");
    newTab.focus();
  };
  render() {
    const dataSource =
      this.state.records &&
      this.state.records.map((item, index) => {
        return {
          key: index,
          name: item.full_name,
          email: item.email,
          mobile: item.mobile,
          current_ctc:
            item.current_ctc_from +
            " Lakh " +
            item.current_ctc_to +
            " Thousand",
          experience:
            item.experience_from + " Year " + item.experience_to + " Months",
          current_location: item.location_name,
          date_of_apply: item.date
            ? moment(item.date).format("DD-MM-YYYY")
            : "---",
          photo: item,
          resume: item,
          company_name: item.company_name,
        };
      });
    const columns = [
      {
        title: "Company Name",
        dataIndex: "company_name",
        key: "company_name",
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Mobile",
        dataIndex: "mobile",
        key: "mobile",
      },
      {
        title: "Current Ctc",
        dataIndex: "current_ctc",
        key: "current_ctc",
      },
      {
        title: "Experience",
        dataIndex: "experience",
        key: "experience",
      },
      {
        title: "Current Location",
        dataIndex: "current_location",
        key: "current_location",
      },
      {
        title: "Date Of Apply",
        dataIndex: "date_of_apply",
        key: "date_of_apply",
      },
      {
        title: "Photo",
        dataIndex: "photo",
        key: "photo",
        render: (item) => {
          return (
            <div>
              {item.photo ? (
                <Button type="primary" onClick={() => this.downloadFile(item)}>
                  <CloudDownloadOutlined /> Download
                </Button>
              ) : (
                <p>Not Found..</p>
              )}
            </div>
          );
        },
      },
      {
        title: "Resume",
        dataIndex: "resume",
        key: "resume",
        render: (item) => {
          return (
            <div>
              {item.resume ? (
                <Button
                  type="primary"
                  onClick={() => this.downloadFileResume(item)}
                >
                  <CloudDownloadOutlined /> Download
                </Button>
              ) : (
                <p>Not Found..</p>
              )}
            </div>
          );
        },
      },
    ];
    return (
      <div>
        <Button type="primary" onClick={() => this.show_modal()}>
          <i
            className="fas fa-file-alt"
            style={{ color: "#000", fontSize: 18 }}
          ></i>
          <span
            style={{
              fontSize: 14,
              marginLeft: 10,
              position: "relative",
              top: -4,
            }}
          >
            ({this.props.data.applied_job_count})&nbsp; View Applied Jobs
          </span>
        </Button>
        <Modal
          title="Applied Job List"
          visible={this.state.modalStatus}
          okText={"Ok"}
          cancelText={"Close"}
          onOk={() => this.closeModal()}
          onCancel={() => this.closeModal()}
          width={800}
        >
          <Spin spinning={this.state.formLoader}>
            <div className="row">
              <div className="col-lg-12">
                <Table
                  dataSource={dataSource}
                  columns={columns}
                  scroll={{
                    x: 1300,
                  }}
                />
              </div>
            </div>
          </Spin>
        </Modal>
      </div>
    );
  }
}
