import React, { Component } from "react";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import { Card, Spin } from "antd";
import axios from "axios";
import URLs from "./utils/apis";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      manpower_enquiry_count: 0,
      total_contact_count: 0,
      total_posted_job_count: 0,
      total_resume_count: 0,
      total_internship_count: 0,
      loading: false,
    };
  }
  componentDidMount() {
    this.get_dashboard_data();
  }
  get_dashboard_data = () => {
    this.setState({ loading: true });
    axios
      .get(URLs.get_dashboard_data)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState({
            manpower_enquiry_count:
              response.data.data.total_manpower_enquiry_count,
            total_contact_count: response.data.data.total_contact_count,
            total_posted_job_count: response.data.data.total_posted_job_count,
            total_resume_count: response.data.data.total_resume_count,
            total_internship_count: response.data.data.total_internship_count,
            loading: false,
          });
          console.log("response", response.data);
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
      });
  };
  render() {
    return (
      <div>
        <Header />
        <div className="content-wrapper">
          <div className="d-flex justify-content-between">
            <Sidebar />
            <div className="main-wrapper">
              <div className="inner">
                <div className="sidebar-space"></div>
                <div className="main-content">
                  <Card className="dashboard-cards">
                    <Spin spinning={this.state.loading}>
                      <div className="row">
                        <div className="col-lg-3">
                          <a
                            href="/posted-jobs"
                            style={{ textDecoration: "none" }}
                          >
                            <Card className="mb-3 card">
                              <h3>{this.state.total_posted_job_count}</h3>
                              <p className="mb-0 pb-0 count-heading">
                                Total Posted Jobs
                              </p>
                            </Card>
                          </a>
                        </div>
                        <div className="col-lg-3">
                          <a href="/resumes" style={{ textDecoration: "none" }}>
                            <Card className="mb-3 card">
                              <h3>{this.state.total_resume_count}</h3>
                              <p className="mb-0 pb-0 count-heading">
                                Total Resumes
                              </p>
                            </Card>
                          </a>
                        </div>
                        <div className="col-lg-3">
                          <a
                            href="/manpower-enquiry"
                            style={{ textDecoration: "none" }}
                          >
                            <Card className="mb-3 card">
                              <h3>{this.state.manpower_enquiry_count}</h3>
                              <p className="mb-0 pb-0 count-heading">
                                Total Manpower Enquiries
                              </p>
                            </Card>
                          </a>
                        </div>
                        {/* <div className="col-lg-3">
                          <Card className="mb-3 card">
                            <h3>{this.state.total_contact_count}</h3>
                            <p className="mb-0 pb-0 count-heading">
                              Total Contacts
                            </p>
                          </Card>
                        </div> */}
                      </div>
                    </Spin>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
