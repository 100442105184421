import React, { Component } from "react";
import { Card, Table, Button, Modal, Input, message, Spin } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import URLs from "../utils/apis";

class Emails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      tableloader: false,
      addCsrModalStatus: false,
      updateCategoryModalStatus: false,
      records: [],
      formLoader: false,
      edit_category_id: "",
      deleteCsrModalStatus: false,
      edit_category_name: "",
      category_name: "",

      email_label: "",
      email_address: "",

      update_email_label: "",
      update_email_address: "",
    };
  }
  componentDidMount() {
    this.get_email_list();
  }
  get_email_list = () => {
    this.setState({ tableloader: true });
    axios
      .get(URLs.get_email_list)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState({
            records: response.data.data,
            tableloader: false,
          });
        } else {
          this.setState({ tableloader: false });
        }
      })
      .catch((error) => {
        this.setState({ tableloader: false });
        console.error("Warning:", error);
      });
  };
  openAddModal = () => {
    this.setState({
      addCsrModalStatus: true,
    });
  };
  openEditModal = (item) => {
    this.setState({
      updateCategoryModalStatus: true,
      edit_category_id: item.id,
      update_email_label: item.label,
      update_email_address: item.email,
    });
  };
  openDeleteModal = (item) => {
    this.setState({
      deleteCsrModalStatus: true,
      edit_category_id: item.id,
    });
  };
  saveEmail = () => {
    if (!this.state.email_label) {
      message.warning("Label is required");
      return;
    }
    if (!this.state.email_address) {
      message.warning("Email address is required");
      return;
    }
    this.setState({ formLoader: true });
    let params = {
      label: this.state.email_label,
      email: this.state.email_address,
    };
    axios
      .post(URLs.add_new_email, params)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState(
            {
              formLoader: false,
              category_name: "",
              addCsrModalStatus: false,
            },
            () => this.get_email_list()
          );
          message.success(response.data.message);
          console.log("response", response.data);
        } else {
          this.setState({ formLoader: false });
          message.warning(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ formLoader: false });
        message.warning("Please try again.");
      });
  };
  updateCategory = () => {
    if (!this.state.update_email_label) {
      message.warning("Label is required");
      return;
    }
    if (!this.state.update_email_address) {
      message.warning("Email address is required");
      return;
    }
    this.setState({ formLoader: false });
    let params = {
      id: this.state.edit_category_id,
      label: this.state.update_email_label,
      email: this.state.update_email_address,
    };
    axios
      .post(URLs.update_email, params)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState(
            {
              formLoader: false,
              updateCategoryModalStatus: false,
              edit_category_name: "",
              edit_category_id: "",
            },
            () => this.get_email_list()
          );
          message.success(response.data.message);
        } else {
          this.setState({ formLoader: false });
          message.warning(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ formLoader: false });
        message.warning("Please try again.");
      });
  };
  deleteClient = () => {
    let params = {
      id: this.state.edit_category_id,
    };
    axios
      .post(URLs.delete_email, params)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState(
            {
              formLoader: false,
              deleteCsrModalStatus: false,
              edit_category_id: "",
            },
            () => this.get_email_list()
          );
          message.success(response.data.message);
        } else {
          this.setState({ formLoader: false });
          message.warning(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ formLoader: false });
        message.warning("Please try again.");
      });
  };
  render() {
    const dataSource =
      this.state.records &&
      this.state.records.map((item, index) => {
        return {
          key: "1",
          sr_no: Number(index + 1),
          label: item.label ? item.label : "---",
          email: item.email ? item.email : "---",
          action: item,
        };
      });
    const columns = [
      {
        title: "Sr No",
        dataIndex: "sr_no",
        key: "sr_no",
        width: 120,
      },
      {
        title: "Label",
        dataIndex: "label",
        key: "label",
        width: 250,
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        render: (item) => {
          return (
            <div>
              <Button
                type="primary"
                className="success"
                onClick={() => this.openEditModal(item)}
              >
                Edit
              </Button>
              <Button
                type="primary"
                danger
                style={{ marginLeft: 10 }}
                onClick={() => this.openDeleteModal(item)}
              >
                Delete
              </Button>
            </div>
          );
        },
      },
    ];
    return (
      <div>
        <div className="row">
          <div className="col-lg-12">
            <Card>
              <Card>
                <div className="d-flex justify-content-between">
                  <h3>Email List - (Total: {this.state.records.length})</h3>
                  <br />
                  <Button
                    type="primary"
                    icon={<PlusCircleOutlined />}
                    onClick={() => this.openAddModal()}
                  >
                    Add New Email
                  </Button>
                </div>
              </Card>
              <br />
              <Card>
                <Table
                  loading={this.state.tableloader}
                  dataSource={dataSource}
                  columns={columns}
                />
              </Card>
            </Card>
          </div>
        </div>
        {/* Add modal  */}
        <Modal
          visible={this.state.addCsrModalStatus}
          title="Add New Email"
          okText="Save"
          cancelText="Cancel"
          width={450}
          onOk={() => this.saveEmail()}
          onCancel={() => this.setState({ addCsrModalStatus: false })}
        >
          <Spin spinning={this.state.formLoader}>
            <div className="mb-2 mt-3">
              <label style={{ fontSize: 16, paddingBottom: 6 }}>Label</label>
              <Input
                type="text"
                value={this.state.email_label}
                onChange={(e) => this.setState({ email_label: e.target.value })}
              />
            </div>
            <div className="mb-2">
              <label style={{ fontSize: 16, paddingBottom: 6 }}>
                Email Address
              </label>
              <Input
                type="text"
                value={this.state.email_address}
                onChange={(e) =>
                  this.setState({ email_address: e.target.value })
                }
              />
            </div>
          </Spin>
        </Modal>

        {/* Edit modal  */}
        <Modal
          visible={this.state.updateCategoryModalStatus}
          title="Edit Email"
          okText="Update"
          cancelText="Cancel"
          width={450}
          onOk={() => this.updateCategory()}
          onCancel={() => {
            this.setState({
              updateCategoryModalStatus: false,
              edit_category_id: "",
              update_email_label: "",
              update_email_address: "",
            });
          }}
        >
          <Spin spinning={this.state.formLoader}>
            <div className="mb-2 mt-3">
              <label style={{ fontSize: 16, paddingBottom: 6 }}>Label</label>
              <Input
                type="text"
                value={this.state.update_email_label}
                onChange={(e) =>
                  this.setState({ update_email_label: e.target.value })
                }
              />
            </div>
            <div className="mb-2">
              <label style={{ fontSize: 16, paddingBottom: 6 }}>
                Email Address
              </label>
              <Input
                type="text"
                value={this.state.update_email_address}
                onChange={(e) =>
                  this.setState({ update_email_address: e.target.value })
                }
              />
            </div>
          </Spin>
        </Modal>

        {/* Delete modal  */}
        <Modal
          visible={this.state.deleteCsrModalStatus}
          title="Delete Email"
          okText="Delete"
          cancelText="Cancel"
          onOk={() => this.deleteClient()}
          onCancel={() => this.setState({ deleteCsrModalStatus: false })}
        >
          <div className="mb-2 mt-4">
            <label style={{ fontSize: 18, paddingBottom: 6 }}>
              Are you sure you want to delete ?
            </label>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Emails;
