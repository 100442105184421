import React, { Component } from "react";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import { Card, Button } from "antd";
import Emails from "./contacts/Emails";
import Offices from "./contacts/Offices";
import ContactDetails from "./contacts/ContactDetails";

class ContactList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      tableloader: false,
    };
  }

  render() {
    return (
      <div>
        <Header />
        <div className="content-wrapper">
          <div className="d-flex justify-content-between">
            <Sidebar />
            <div className="main-wrapper">
              <div className="inner">
                <div className="sidebar-space"></div>
                <div className="main-content">
                  <Card>
                    <Card>
                      <div className="d-flex justify-content-between">
                        <h3>All Contact Masters</h3>
                      </div>
                    </Card>
                    <br />
                    <div className="row">
                      <div className="col-lg-12">
                        <Emails />
                        <br />
                      </div>
                      <div className="col-lg-12">
                        <Offices />
                        <br />
                      </div>
                      <div className="col-lg-6">
                        <ContactDetails />
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactList;
