import React, { Component } from "react";
import { Card, Table, Button, Modal, Input, message } from "antd";
import axios from "axios";
import URLs from "../../utils/apis";

export default class Role extends Component {
  constructor(props) {
    super(props);
    this.state = {
      records: [],
      tableloader: false,
      addModalStatus: false,
      editModalStatus: false,
      deleteModalStatus: false,

      role_name: "",
      edit_role_name: "",
      role_id: "",
    };
  }
  componentDidMount() {
    this.get_category_list();
  }
  get_category_list = () => {
    this.setState({ tableloader: true });
    axios
      .get(URLs.get_role_list)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState({
            records: response.data.data,
            tableloader: false,
          });
        } else {
          this.setState({ tableloader: false });
        }
      })
      .catch((error) => {
        this.setState({ tableloader: false });
        console.error("Warning:", error);
      });
  };
  openAddModal = () => {
    this.setState({ addModalStatus: true });
  };
  saveLocation = () => {
    if (!this.state.role_name) {
      message.warning("Category name is required");
      return;
    }
    this.setState({ formLoader: false });
    let params = {
      name: this.state.role_name,
    };
    axios
      .post(URLs.add_new_role, params)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState(
            {
              formLoader: false,
              role_name: "",
              addModalStatus: false,
            },
            () => this.get_category_list()
          );
          message.success(response.data.message);
        } else {
          this.setState({ formLoader: false });
          message.warning(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ formLoader: false });
        message.warning("Please try again.");
      });
  };
  // edit
  openEditModal = (item) => {
    this.setState({
      editModalStatus: true,
      edit_role_name: item.role_name,
      role_id: item.role_id,
    });
  };
  openDeleteModal = (item) => {
    this.setState({
      deleteModalStatus: true,
      role_id: item.role_id,
    });
  };

  updateLocation = () => {
    if (!this.state.edit_role_name) {
      message.warning("Category name is required");
      return;
    }
    if (!this.state.role_id) {
      message.warning("Invalid request");
      return;
    }
    this.setState({ formLoader: false });
    let params = {
      name: this.state.edit_role_name,
      id: this.state.role_id,
    };
    axios
      .post(URLs.update_role, params)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState(
            {
              formLoader: false,
              edit_role_name: "",
              editModalStatus: false,
              role_id: "",
            },
            () => this.get_category_list()
          );
          message.success(response.data.message);
        } else {
          this.setState({ formLoader: false });
          message.warning(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ formLoader: false });
        message.warning("Please try again.");
      });
  };
  deleteLocation = () => {
    if (!this.state.role_id) {
      message.warning("Invalid request");
      return;
    }
    this.setState({ formLoader: false });
    let params = {
      id: this.state.role_id,
    };
    axios
      .post(URLs.delete_role, params)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState(
            {
              formLoader: false,
              deleteModalStatus: false,
              role_id: "",
            },
            () => this.get_category_list()
          );
          message.success(response.data.message);
        } else {
          this.setState({ formLoader: false });
          message.warning(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ formLoader: false });
        message.warning("Please try again.");
      });
  };
  render() {
    const dataSource =
      this.state.records &&
      this.state.records.map((item, index) => {
        return {
          key: "1",
          sr_no: Number(index + 1),
          role_name: item.role_name ? item.role_name : "---",
          action: item,
        };
      });
    const columns = [
      {
        title: "Sr No",
        dataIndex: "sr_no",
        key: "sr_no",
        width: 120,
      },
      {
        title: "Role Name",
        dataIndex: "role_name",
        key: "role_name",
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        render: (item) => {
          return (
            <div>
              <Button
                type="primary"
                className="success"
                onClick={() => this.openEditModal(item)}
              >
                Edit
              </Button>
              <Button
                type="primary"
                danger
                style={{ marginLeft: 0 }}
                onClick={() => this.openDeleteModal(item)}
              >
                Delete
              </Button>
            </div>
          );
        },
      },
    ];
    return (
      <div>
        <Card>
          <h4>Role List</h4>
          <Button
            type="primary"
            style={{ marginLeft: 10 }}
            onClick={() => this.openAddModal()}
          >
            Add New Role
          </Button>
          <Table
            loading={this.state.tableloader}
            dataSource={dataSource}
            columns={columns}
            pagination={{ pageSize: 5 }}
          />
        </Card>
        {/* Add modal  */}
        <Modal
          visible={this.state.addModalStatus}
          title="Add New Role"
          okText="Save"
          cancelText="Cancel"
          onOk={() => this.saveLocation()}
          onCancel={() => this.setState({ addModalStatus: false })}
        >
          <div className="mb-5 mt-5">
            <label style={{ fontSize: 16, paddingBottom: 6 }}>Role Name</label>
            <Input
              type="text"
              value={this.state.role_name}
              onChange={(e) => this.setState({ role_name: e.target.value })}
            />
          </div>
        </Modal>

        <Modal
          visible={this.state.editModalStatus}
          title="Edit Role"
          okText="Update"
          cancelText="Cancel"
          onOk={() => this.updateLocation()}
          onCancel={() => this.setState({ editModalStatus: false })}
        >
          <div className="mb-5 mt-5">
            <label style={{ fontSize: 16, paddingBottom: 6 }}>Role Name</label>
            <Input
              type="text"
              value={this.state.edit_role_name}
              onChange={(e) =>
                this.setState({ edit_role_name: e.target.value })
              }
            />
          </div>
        </Modal>

        <Modal
          visible={this.state.deleteModalStatus}
          title="Delete Role"
          okText="Delete"
          cancelText="Close"
          onOk={() => this.deleteLocation()}
          onCancel={() => this.setState({ deleteModalStatus: false })}
        >
          <div className="mb-2 mt-2">
            <label style={{ fontSize: 16, paddingBottom: 6 }}>
              Are you sure you want to delete?
            </label>
          </div>
        </Modal>
      </div>
    );
  }
}
