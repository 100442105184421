import React, { Component } from "react";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import { Card, Table, Button, Modal, Input, message } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import URLs from "./utils/apis";
import { BASE_URL } from "./utils/consts";

class Clients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      tableloader: false,
      addClientModalStatus: false,
      updateClientModalStatus: false,
      client_img_url: "",
      client_file_name: "",
      edit_client_file_name: "",
      records: [],
      formLoader: false,
      edlt_client_id: "",
      deleteClientModalStatus: false,
    };
  }
  componentDidMount() {
    this.get_client_list();
  }
  get_client_list = () => {
    this.setState({ tableloader: true });
    axios
      .get(URLs.get_client_list)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState({
            records: response.data.data,
            tableloader: false,
          });
          console.log("response", response.data);
        } else {
          this.setState({ tableloader: false });
        }
      })
      .catch((error) => {
        this.setState({ tableloader: false });
        console.error("Warning:", error);
      });
  };
  openAddModal = () => {
    this.setState({
      addClientModalStatus: true,
    });
  };
  openEditModal = (item) => {
    let url = BASE_URL + "/" + item.logo_file_name;
    this.setState({
      updateClientModalStatus: true,
      edit_client_id: item.client_id,
      client_img_url: url,
    });
  };
  openDeleteModal = (item) => {
    let url = BASE_URL + "/" + item.logo_file_name;
    this.setState({
      deleteClientModalStatus: true,
      edit_client_id: item.client_id,
    });
  };
  saveClient = () => {
    if (!this.state.client_file_name) {
      message.warning("Please select file");
      return;
    }
    this.setState({ formLoader: false });
    let formdata = new FormData();
    formdata.append("client_image", this.state.client_file_name);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    axios
      .post(URLs.add_new_client, formdata, config)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState(
            {
              formLoader: false,
              addClientModalStatus: false,
            },
            () => this.get_client_list()
          );
          message.success(response.data.message);
          console.log("response", response.data);
        } else {
          this.setState({ formLoader: false });
          message.warning(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ formLoader: false });
        message.warning("Please try again.");
      });
  };
  updateClient = () => {
    if (!this.state.edit_client_file_name) {
      message.warning("Please select file");
      return;
    }
    this.setState({ formLoader: false });
    let formdata = new FormData();
    formdata.append("client_image", this.state.edit_client_file_name);
    formdata.append("id", this.state.edit_client_id);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    axios
      .post(URLs.update_client, formdata, config)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState(
            {
              formLoader: false,
              updateClientModalStatus: false,
            },
            () => this.get_client_list()
          );
          message.success(response.data.message);
          console.log("response", response.data);
        } else {
          this.setState({ formLoader: false });
          message.warning(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ formLoader: false });
        message.warning("Please try again.");
      });
  };
  deleteClient = () => {
    let params = {
      client_id: this.state.edit_client_id,
    };
    axios
      .post(URLs.delete_client, params)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState(
            {
              formLoader: false,
              deleteClientModalStatus: false,
            },
            () => this.get_client_list()
          );
          message.success(response.data.message);
        } else {
          this.setState({ formLoader: false });
          message.warning(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ formLoader: false });
        message.warning("Please try again.");
      });
  };
  render() {
    const dataSource =
      this.state.records &&
      this.state.records.map((item, index) => {
        return {
          key: "1",
          sr_no: Number(index + 1),
          client_image: item,
          action: item,
        };
      });
    const columns = [
      {
        title: "Sr No",
        dataIndex: "sr_no",
        key: "sr_no",
      },
      {
        title: "Client Image",
        dataIndex: "client_image",
        key: "client_image",
        render: (item) => {
          let url = BASE_URL + "/" + item.logo_file_name;
          return (
            <div>
              <img src={url} style={{ maxWidth: 150 }} />
            </div>
          );
        },
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        render: (item) => {
          return (
            <div>
              <Button
                type="primary"
                className="success"
                onClick={() => this.openEditModal(item)}
              >
                Edit
              </Button>
              <Button
                type="primary"
                danger
                style={{ marginLeft: 10 }}
                onClick={() => this.openDeleteModal(item)}
              >
                Delete
              </Button>
            </div>
          );
        },
      },
    ];
    return (
      <div>
        <Header />
        <div className="content-wrapper">
          <div className="d-flex justify-content-between">
            <Sidebar />
            <div className="main-wrapper">
              <div className="inner">
                <div className="sidebar-space"></div>
                <div className="main-content">
                  <div className="row">
                    <div className="col-lg-12">
                      <Card>
                        <Card>
                          <div className="d-flex justify-content-between">
                            <h3>
                              Client List - (Total: {this.state.records.length})
                            </h3>
                            <Button
                              type="primary"
                              icon={<PlusCircleOutlined />}
                              onClick={() => this.openAddModal()}
                            >
                              Add New Client
                            </Button>
                          </div>
                        </Card>
                        <br />
                        <Card>
                          <Table
                            loading={this.state.tableloader}
                            dataSource={dataSource}
                            columns={columns}
                          />
                        </Card>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Add modal  */}
        <Modal
          visible={this.state.addClientModalStatus}
          title="Add New Client"
          okText="Save"
          cancelText="Cancel"
          onOk={() => this.saveClient()}
          onCancel={() => this.setState({ addClientModalStatus: false })}
        >
          <div className="mb-5 mt-5">
            <label style={{ fontSize: 16, paddingBottom: 6 }}>
              Select Client Image
            </label>
            <Input
              type="file"
              accept="image/*"
              onChange={(e) =>
                this.setState({ client_file_name: e.target.files[0] })
              }
            />
          </div>
        </Modal>

        {/* Edit modal  */}
        <Modal
          visible={this.state.updateClientModalStatus}
          title="Edit Client Image"
          okText="Update"
          cancelText="Cancel"
          onOk={() => this.updateClient()}
          onCancel={() => this.setState({ updateClientModalStatus: false })}
        >
          <div className="mb-5 mt-5">
            <label style={{ fontSize: 16, paddingBottom: 6 }}>
              Select Client Image
            </label>
            {this.state.client_img_url && (
              <div>
                <a href={this.state.client_img_url} target="_blank">
                  <img
                    src={this.state.client_img_url}
                    style={{
                      maxWidth: 100,
                      borderRadius: 5,
                      marginBottom: 13,
                      padding: 4,
                      border: "1px solid #ddd",
                    }}
                  />
                </a>
              </div>
            )}
            <Input
              type="file"
              accept="image/*"
              onChange={(e) =>
                this.setState({ edit_client_file_name: e.target.files[0] })
              }
            />
          </div>
        </Modal>

        {/* Delete modal  */}
        <Modal
          visible={this.state.deleteClientModalStatus}
          title="Delete Client"
          okText="Delete"
          cancelText="Cancel"
          onOk={() => this.deleteClient()}
          onCancel={() => this.setState({ deleteClientModalStatus: false })}
        >
          <div className="mb-2 mt-4">
            <label style={{ fontSize: 18, paddingBottom: 6 }}>
              Are you sure you want to delete ?
            </label>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Clients;
