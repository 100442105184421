import React, { Component } from "react";
import { Card, Table, Button, Modal, Input, message, Spin } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import URLs from "../utils/apis";

const { TextArea } = Input;

class Offices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      tableloader: false,
      addCsrModalStatus: false,
      updateCategoryModalStatus: false,
      records: [],
      formLoader: false,
      edit_category_id: "",
      deleteCsrModalStatus: false,
      edit_category_name: "",
      category_name: "",

      office_name: "",
      address: "",
      contact_no: "",
      whatsapp_no: "",
      email: "",

      edit_office_name: "",
      edit_address: "",
      edit_contact_no: "",
      edit_whatsapp_no: "",
      edit_email: "",
      office_id: "",
    };
  }
  componentDidMount() {
    this.get_office_list();
  }
  get_office_list = () => {
    this.setState({ tableloader: true });
    axios
      .get(URLs.get_office_list)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState({
            records: response.data.data,
            tableloader: false,
          });
        } else {
          this.setState({ tableloader: false });
        }
      })
      .catch((error) => {
        this.setState({ tableloader: false });
        console.error("Warning:", error);
      });
  };
  openAddModal = () => {
    this.setState({
      addCsrModalStatus: true,
    });
  };
  openEditModal = (item) => {
    this.setState({
      updateCategoryModalStatus: true,
      office_id: item.o_id,
      edit_office_name: item.office_type,
      edit_address: item.address,
      edit_contact_no: item.contact_no,
      edit_whatsapp_no: item.whatsapp_no,
      edit_email: item.email,
    });
  };
  openDeleteModal = (item) => {
    this.setState({
      deleteCsrModalStatus: true,
      office_id: item.o_id,
    });
  };
  saveCategory = () => {
    if (!this.state.office_name) {
      message.warning("Office is required");
      return;
    }
    if (!this.state.address) {
      message.warning("Address is required");
      return;
    }
    if (!this.state.contact_no) {
      message.warning("Contact no is required");
      return;
    }
    if (this.state.contact_no.length != 10) {
      message.warning("Invalid Contact no");
      return;
    }
    if (!this.state.whatsapp_no) {
      message.warning("Whatsapp no is required");
      return;
    }
    // if (!this.state.email) {
    //   message.warning("Email is required");
    //   return;
    // }
    this.setState({ formLoader: false });
    let params = {
      office_type: this.state.office_name,
      address: this.state.address,
      contact_no: this.state.contact_no,
      whatsapp_no: this.state.whatsapp_no,
      email: this.state.email,
    };
    axios
      .post(URLs.add_new_office, params)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState(
            {
              formLoader: false,
              office_name: "",
              address: "",
              contact_no: "",
              whatsapp_no: "",
              email: "",
              addCsrModalStatus: false,
            },
            () => this.get_office_list()
          );
          message.success(response.data.message);
          console.log("response", response.data);
        } else {
          this.setState({ formLoader: false });
          message.warning(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ formLoader: false });
        message.warning("Please try again.");
      });
  };
  updateCategory = () => {
    if (!this.state.edit_office_name) {
      message.warning("Office is required");
      return;
    }
    if (!this.state.edit_address) {
      message.warning("Address is required");
      return;
    }
    if (!this.state.edit_contact_no) {
      message.warning("Contact no is required");
      return;
    }
    if (this.state.edit_contact_no.length != 10) {
      message.warning("Invalid Contact no");
      return;
    }
    if (!this.state.edit_whatsapp_no) {
      message.warning("Whatsapp no is required");
      return;
    }
    // if (!this.state.edit_email) {
    //   message.warning("Email is required");
    //   return;
    // }
    this.setState({ formLoader: false });
    let params = {
      id: this.state.office_id,
      office_type: this.state.edit_office_name,
      address: this.state.edit_address,
      contact_no: this.state.edit_contact_no,
      whatsapp_no: this.state.edit_whatsapp_no,
      email: this.state.edit_email,
    };
    axios
      .post(URLs.update_office, params)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState(
            {
              formLoader: false,
              updateCategoryModalStatus: false,
              edit_office_name: "",
              edit_address: "",
              edit_contact_no: "",
              edit_email: "",
              office_id: "",
            },
            () => this.get_office_list()
          );
          message.success(response.data.message);
        } else {
          this.setState({ formLoader: false });
          message.warning(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ formLoader: false });
        message.warning("Please try again.");
      });
  };
  deleteClient = () => {
    let params = {
      id: this.state.office_id,
    };
    axios
      .post(URLs.delete_office, params)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState(
            {
              formLoader: false,
              deleteCsrModalStatus: false,
              office_id: "",
            },
            () => this.get_office_list()
          );
          message.success(response.data.message);
        } else {
          this.setState({ formLoader: false });
          message.warning(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ formLoader: false });
        message.warning("Please try again.");
      });
  };
  render() {
    const dataSource =
      this.state.records &&
      this.state.records.map((item, index) => {
        return {
          key: "1",
          sr_no: Number(index + 1),
          office: item.office_type ? item.office_type : "---",
          email: item.email ? item.email : "---",
          whatsapp_no: item.whatsapp_no ? item.whatsapp_no : "---",
          contact_no: item.contact_no ? item.contact_no : "---",
          address: item.address ? item.address : "---",
          action: item,
        };
      });
    const columns = [
      {
        title: "Sr No",
        dataIndex: "sr_no",
        key: "sr_no",
        width: 90,
      },
      {
        title: "Office",
        dataIndex: "office",
        key: "office",
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        width: 90,
      },
      {
        title: "Contact No",
        dataIndex: "contact_no",
        key: "contact_no",
        width: 120,
      },
      {
        title: "Whatsapp No",
        dataIndex: "whatsapp_no",
        key: "whatsapp_no",
        width: 120,
      },
      {
        title: "Address",
        dataIndex: "address",
        key: "address",
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        render: (item) => {
          return (
            <div>
              <Button
                type="primary"
                className="success"
                onClick={() => this.openEditModal(item)}
              >
                Edit
              </Button>
              <Button
                type="primary"
                danger
                style={{ marginLeft: 10 }}
                onClick={() => this.openDeleteModal(item)}
              >
                Delete
              </Button>
            </div>
          );
        },
      },
    ];
    return (
      <div>
        <div className="row">
          <div className="col-lg-12">
            <Card>
              <Card>
                <div className="d-flex justify-content-between">
                  <h3>Office List - (Total: {this.state.records.length})</h3>
                  <Button
                    type="primary"
                    icon={<PlusCircleOutlined />}
                    onClick={() => this.openAddModal()}
                  >
                    Add New Office
                  </Button>
                </div>
              </Card>
              <br />
              <Card>
                <Table
                  loading={this.state.tableloader}
                  dataSource={dataSource}
                  columns={columns}
                />
              </Card>
            </Card>
          </div>
        </div>
        {/* Add modal  */}
        <Modal
          visible={this.state.addCsrModalStatus}
          title="Add New Office"
          okText="Save"
          cancelText="Cancel"
          onOk={() => this.saveCategory()}
          onCancel={() => this.setState({ addCsrModalStatus: false })}
        >
          <Spin spinning={this.state.formLoader}>
            <div className="mb-2 mt-3">
              <label style={{ fontSize: 16, paddingBottom: 6 }}>
                Office
                <span className="text-danger">*</span>
              </label>
              <select
                name="office_name"
                value={this.state.office_name}
                onChange={(e) => this.setState({ office_name: e.target.value })}
                className="form-control"
              >
                <option value="">Select office</option>
                <option value="Registered Office">Registered Office</option>
                <option value="Corporate Office">Corporate Office</option>
                <option value="Regional Office">Regional Office</option>
                <option value="Branch Office">Branch Office</option>
                <option value="Franchise">Franchise</option>
              </select>
            </div>
            <div className="mb-2">
              <label style={{ fontSize: 16, paddingBottom: 6 }}>
                Address <span className="text-danger">*</span>
              </label>
              <textarea
                name="address"
                class="form-control"
                cols="30"
                rows="2"
                spellcheck="false"
                value={this.state.address}
                onChange={(e) => this.setState({ address: e.target.value })}
              ></textarea>
            </div>
            <div className="mb-2">
              <label style={{ fontSize: 16, paddingBottom: 6 }}>
                Contact No <span className="text-danger">*</span>
              </label>
              <Input
                type="text"
                maxLength={10}
                value={this.state.contact_no}
                onChange={(e) => this.setState({ contact_no: e.target.value })}
              />
            </div>
            <div className="mb-2">
              <label style={{ fontSize: 16, paddingBottom: 6 }}>
                Whatsapp No <span className="text-danger">*</span>
              </label>
              <Input
                maxLength={10}
                type="text"
                value={this.state.whatsapp_no}
                onChange={(e) => this.setState({ whatsapp_no: e.target.value })}
              />
            </div>
            <div className="mb-2">
              <label style={{ fontSize: 16, paddingBottom: 6 }}>Email</label>
              <Input
                type="text"
                value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })}
              />
            </div>
          </Spin>
        </Modal>

        {/* Edit modal  */}
        <Modal
          visible={this.state.updateCategoryModalStatus}
          title="Edit Office"
          okText="Update"
          cancelText="Cancel"
          onOk={() => this.updateCategory()}
          onCancel={() => {
            this.setState({
              updateCategoryModalStatus: false,
              edit_office_name: "",
              edit_address: "",
              edit_contact_no: "",
              edit_whatsapp_no: "",
              edit_email: "",
              office_id: "",
            });
          }}
        >
          <Spin spinning={this.state.formLoader}>
            <div className="mb-2 mt-3">
              <label style={{ fontSize: 16, paddingBottom: 6 }}>
                Office
                <span className="text-danger">*</span>
              </label>
              <select
                name="office_name"
                value={this.state.edit_office_name}
                onChange={(e) =>
                  this.setState({ edit_office_name: e.target.value })
                }
                className="form-control"
              >
                <option value="">Select office</option>
                <option value="Registered Office">Registered Office</option>
                <option value="Corporate Office">Corporate Office</option>
                <option value="Regional Office">Regional Office</option>
                <option value="Branch Office">Branch Office</option>
                <option value="Franchise">Franchise</option>
              </select>
            </div>
            <div className="mb-2">
              <label style={{ fontSize: 16, paddingBottom: 6 }}>
                Address <span className="text-danger">*</span>
              </label>
              <textarea
                name="address"
                class="form-control"
                cols="30"
                rows="2"
                spellcheck="false"
                value={this.state.edit_address}
                onChange={(e) =>
                  this.setState({ edit_address: e.target.value })
                }
              ></textarea>
            </div>
            <div className="mb-2">
              <label style={{ fontSize: 16, paddingBottom: 6 }}>
                Contact No <span className="text-danger">*</span>
              </label>
              <Input
                type="text"
                maxLength={10}
                value={this.state.edit_contact_no}
                onChange={(e) =>
                  this.setState({ edit_contact_no: e.target.value })
                }
              />
            </div>
            <div className="mb-2">
              <label style={{ fontSize: 16, paddingBottom: 6 }}>
                Whatsapp No <span className="text-danger">*</span>
              </label>
              <Input
                maxLength={10}
                type="text"
                value={this.state.edit_whatsapp_no}
                onChange={(e) =>
                  this.setState({ edit_whatsapp_no: e.target.value })
                }
              />
            </div>
            <div className="mb-2">
              <label style={{ fontSize: 16, paddingBottom: 6 }}>Email</label>
              <Input
                type="text"
                value={this.state.edit_email}
                onChange={(e) => this.setState({ edit_email: e.target.value })}
              />
            </div>
          </Spin>
        </Modal>

        {/* Delete modal  */}
        <Modal
          visible={this.state.deleteCsrModalStatus}
          title="Delete Office"
          okText="Delete"
          cancelText="Cancel"
          onOk={() => this.deleteClient()}
          onCancel={() => this.setState({ deleteCsrModalStatus: false })}
        >
          <div className="mb-2 mt-4">
            <label style={{ fontSize: 18, paddingBottom: 6 }}>
              Are you sure you want to delete ?
            </label>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Offices;
