import React, { Component } from "react";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import { Card, Table, Button, Modal, Input, message } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import URLs from "./utils/apis";
import { BASE_URL } from "./utils/consts";

const { TextArea } = Input;

class Csr extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      description: "",
      tableloader: false,
      addCsrModalStatus: false,
      updateCsrModalStatus: false,
      csr_img_url: "",
      client_file_name: "",
      edit_client_file_name: "",
      records: [],
      formLoader: false,
      edlt_client_id: "",
      deleteCsrModalStatus: false,
      edit_description: "",
      edit_csr_image: "",
    };
  }
  componentDidMount() {
    this.get_csr_list();
  }
  get_csr_list = () => {
    this.setState({ tableloader: true });
    axios
      .get(URLs.get_csr_list)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState({
            records: response.data.data,
            tableloader: false,
          });
        } else {
          this.setState({ tableloader: false });
        }
      })
      .catch((error) => {
        this.setState({ tableloader: false });
        console.error("Warning:", error);
      });
  };
  openAddModal = () => {
    this.setState({
      addCsrModalStatus: true,
    });
  };
  openEditModal = (item) => {
    let url = BASE_URL + "/" + item.image;
    this.setState({
      updateCsrModalStatus: true,
      edit_csr_id: item.csr_id,
      csr_img_url: url,
      edit_description: item.description,
    });
  };
  openDeleteModal = (item) => {
    this.setState({
      deleteCsrModalStatus: true,
      edit_csr_id: item.csr_id,
    });
  };
  saveCSR = () => {
    if (!this.state.csr_image) {
      message.warning("Please select CSR image");
      return;
    }
    if (!this.state.description) {
      message.warning("Decription is required");
      return;
    }
    this.setState({ formLoader: false });
    let formdata = new FormData();
    formdata.append("csr_image", this.state.csr_image);
    formdata.append("description", this.state.description);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    axios
      .post(URLs.add_new_csr, formdata, config)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState(
            {
              formLoader: false,
              addCsrModalStatus: false,
            },
            () => this.get_csr_list()
          );
          message.success(response.data.message);
          console.log("response", response.data);
        } else {
          this.setState({ formLoader: false });
          message.warning(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ formLoader: false });
        message.warning("Please try again.");
      });
  };
  updateCsr = () => {
    if (!this.state.edit_description) {
      message.warning("Description is required");
      return;
    }
    this.setState({ formLoader: false });
    let formdata = new FormData();
    formdata.append("csr_image", this.state.edit_csr_image);
    formdata.append("description", this.state.edit_description);
    formdata.append("id", this.state.edit_csr_id);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    axios
      .post(URLs.update_csr, formdata, config)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState(
            {
              formLoader: false,
              updateCsrModalStatus: false,
            },
            () => this.get_csr_list()
          );
          message.success(response.data.message);
          console.log("response", response.data);
        } else {
          this.setState({ formLoader: false });
          message.warning(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ formLoader: false });
        message.warning("Please try again.");
      });
  };
  deleteClient = () => {
    let params = {
      csr_id: this.state.edit_csr_id,
    };
    axios
      .post(URLs.delete_csr, params)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState(
            {
              formLoader: false,
              deleteCsrModalStatus: false,
            },
            () => this.get_csr_list()
          );
          message.success(response.data.message);
        } else {
          this.setState({ formLoader: false });
          message.warning(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ formLoader: false });
        message.warning("Please try again.");
      });
  };
  render() {
    const dataSource =
      this.state.records &&
      this.state.records.map((item, index) => {
        return {
          key: "1",
          sr_no: Number(index + 1),
          client_image: item,
          desc: item,
          action: item,
        };
      });
    const columns = [
      {
        title: "Sr No",
        dataIndex: "sr_no",
        key: "sr_no",
        width: 70,
      },
      {
        title: "CSR Image",
        dataIndex: "client_image",
        key: "client_image",
        render: (item) => {
          let url = BASE_URL + "/" + item.image;
          return (
            <div>
              <img src={url} style={{ maxWidth: 150 }} />
            </div>
          );
        },
      },
      {
        title: "Description",
        dataIndex: "desc",
        key: "desc",
        width: 400,
        render: (item) => {
          let splited_desc =
            item.description.length > 100
              ? item.description.slice(0, 100) + "..."
              : item.description;
          return (
            <div>
              <p>{splited_desc}</p>
            </div>
          );
        },
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        render: (item) => {
          return (
            <div>
              <Button
                type="primary"
                className="success"
                onClick={() => this.openEditModal(item)}
              >
                Edit
              </Button>
              <Button
                type="primary"
                danger
                style={{ marginLeft: 10 }}
                onClick={() => this.openDeleteModal(item)}
              >
                Delete
              </Button>
            </div>
          );
        },
      },
    ];
    return (
      <div>
        <Header />
        <div className="content-wrapper">
          <div className="d-flex justify-content-between">
            <Sidebar />
            <div className="main-wrapper">
              <div className="inner">
                <div className="sidebar-space"></div>
                <div className="main-content">
                  <div className="row">
                    <div className="col-lg-12">
                      <Card>
                        <Card>
                          <div className="d-flex justify-content-between">
                            <h3>
                              CSR List - (Total: {this.state.records.length})
                            </h3>
                            <Button
                              type="primary"
                              icon={<PlusCircleOutlined />}
                              onClick={() => this.openAddModal()}
                            >
                              Add New CSR
                            </Button>
                          </div>
                        </Card>
                        <br />
                        <Card>
                          <Table
                            loading={this.state.tableloader}
                            dataSource={dataSource}
                            columns={columns}
                          />
                        </Card>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Add modal  */}
        <Modal
          visible={this.state.addCsrModalStatus}
          title="Add New CSR"
          okText="Save"
          cancelText="Cancel"
          onOk={() => this.saveCSR()}
          onCancel={() => this.setState({ addCsrModalStatus: false })}
        >
          <div className="mb-3 mt-5">
            <label style={{ fontSize: 16, paddingBottom: 6 }}>
              Select CSR Image
            </label>
            <Input
              type="file"
              accept="image/*"
              onChange={(e) => this.setState({ csr_image: e.target.files[0] })}
            />
          </div>
          <div className="mb-5 mt-3">
            <label style={{ fontSize: 16, paddingBottom: 6 }}>
              Description
            </label>
            <TextArea
              rows={4}
              onChange={(e) => this.setState({ description: e.target.value })}
              value={this.state.description}
            />
          </div>
        </Modal>

        {/* Edit modal  */}
        <Modal
          visible={this.state.updateCsrModalStatus}
          title="Edit CSR Image"
          okText="Update"
          cancelText="Cancel"
          onOk={() => this.updateCsr()}
          onCancel={() => this.setState({ updateCsrModalStatus: false })}
        >
          <div className="mb-5 mt-5">
            <label style={{ fontSize: 16, paddingBottom: 6 }}>
              Select CSR Image
            </label>
            {this.state.csr_img_url && (
              <div>
                <a href={this.state.csr_img_url} target="_blank">
                  <img
                    src={this.state.csr_img_url}
                    style={{
                      maxWidth: 100,
                      borderRadius: 5,
                      marginBottom: 13,
                      padding: 4,
                      border: "1px solid #ddd",
                    }}
                  />
                </a>
              </div>
            )}
            <Input
              type="file"
              accept="image/*"
              onChange={(e) =>
                this.setState({ edit_csr_image: e.target.files[0] })
              }
            />
          </div>
          <div className="mb-5 mt-3">
            <label style={{ fontSize: 16, paddingBottom: 6 }}>
              Description
            </label>
            <TextArea
              rows={4}
              onChange={(e) =>
                this.setState({ edit_description: e.target.value })
              }
              value={this.state.edit_description}
            />
          </div>
        </Modal>

        {/* Delete modal  */}
        <Modal
          visible={this.state.deleteCsrModalStatus}
          title="Delete CSR Record"
          okText="Delete"
          cancelText="Cancel"
          onOk={() => this.deleteClient()}
          onCancel={() => this.setState({ deleteCsrModalStatus: false })}
        >
          <div className="mb-2 mt-4">
            <label style={{ fontSize: 18, paddingBottom: 6 }}>
              Are you sure you want to delete ?
            </label>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Csr;
