import React, { Component } from "react";

export default class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      webSettingDropdown: false,
      invoiceDropdown: false,
      resumesDropdown: false,
      webSettingsDropdown: false,
    };
  }
  toggleMenu = (param) => {
    if (param == "web-settings") {
      this.setState({ webSettingsDropdown: !this.state.webSettingsDropdown });
    } else if (param == "resumes") {
      this.setState({
        resumesDropdown: !this.state.resumesDropdown,
      });
    }
  };
  render() {
    return (
      <div className="sidebar-section">
        <li
          className={window.location.pathname == "/dashboard" ? "active" : ""}
        >
          <a href="/dashboard">
            <i class="bx bxs-bar-chart-alt-2 option-icon"></i> Dashboard
          </a>
        </li>
        <li
          className={
            window.location.pathname == "/posted-jobs" ||
            window.location.pathname == "/post-new-job"
              ? "active"
              : ""
          }
        >
          <a href="/posted-jobs">
            <i class="bx bxs-bar-chart-alt-2 option-icon"></i> Posted Jobs
          </a>
        </li>
        <li
          className={
            window.location.pathname == "/resumes" ||
            window.location.pathname == "/internship-list"
              ? "active"
              : ""
          }
          onClick={() => this.toggleMenu("resumes")}
        >
          <a href="#!">
            <i class="bx bx-git-pull-request option-icon"></i>
            Resumes
            {this.state.resumesDropdown == true ? (
              <i className="bx bx-chevron-up drop-arrow"></i>
            ) : (
              <i className="bx bx-chevron-down drop-arrow"></i>
            )}
          </a>
        </li>
        {this.state.resumesDropdown == true && (
          <div className="pl-20">
            <li
              style={{
                paddingLeft: 26,
                paddingRight: 0,
              }}
              className={window.location.pathname == "/resumes" ? "active" : ""}
            >
              <a href="/resumes">Resume List</a>
            </li>
            <li
              style={{
                paddingLeft: 26,
                paddingRight: 0,
              }}
              className={
                window.location.pathname == "/internship-list" ? "active" : ""
              }
            >
              <a href="/internship-list">Internship</a>
            </li>
          </div>
        )}
        <li className={window.location.pathname == "/services" ? "active" : ""}>
          <a href="/services">
            <i class="bx bxs-bar-chart-alt-2 option-icon"></i> Services
          </a>
        </li>
        <li
          className={
            window.location.pathname == "/manpower-enquiry" ? "active" : ""
          }
        >
          <a href="/manpower-enquiry">
            <i class="bx bxs-bar-chart-alt-2 option-icon"></i> Manpower Enquiry
          </a>
        </li>
        {/* <li
          className={window.location.pathname == "/contact-us" ? "active" : ""}
        >
          <a href="/contact-us">
            <i class="bx bxs-bar-chart-alt-2 option-icon"></i> Contact Us
          </a>
        </li> */}
        <li
          className={
            window.location.pathname == "/home-page" ||
            window.location.pathname == "/web-settings/states" ||
            window.location.pathname == "/masters"
              ? "active"
              : ""
          }
          onClick={() => this.toggleMenu("web-settings")}
        >
          <a href="#!">
            <i class="bx bx-git-pull-request option-icon"></i>
            Web Settings
            {this.state.webSettingsDropdown == true ? (
              <i className="bx bx-chevron-up drop-arrow"></i>
            ) : (
              <i className="bx bx-chevron-down drop-arrow"></i>
            )}
          </a>
        </li>
        {this.state.webSettingsDropdown == true && (
          <div className="pl-20">
            <li
              style={{
                paddingLeft: 26,
                paddingRight: 0,
              }}
              className={
                window.location.pathname == "/home-page" ? "active" : ""
              }
            >
              <a href="/home-page">Home Page</a>
            </li>
            <li
              className={window.location.pathname == "/masters" ? "active" : ""}
              style={{
                paddingLeft: 26,
                paddingRight: 0,
              }}
            >
              <a href="/masters">
                {/* <i class="bx bxs-bar-chart-alt-2 option-icon"></i>  */}
                Masters
              </a>
            </li>
            <li
              className={
                window.location.pathname == "/categories" ? "active" : ""
              }
              style={{
                paddingLeft: 26,
                paddingRight: 0,
              }}
            >
              <a href="/categories">
                {/* <i class="bx bxs-bar-chart-alt-2 option-icon"></i>  */}
                Categories
              </a>
            </li>
            <li
              style={{
                paddingLeft: 26,
                paddingRight: 0,
              }}
              className={window.location.pathname == "/states" ? "active" : ""}
            >
              <a href="/states">States</a>
            </li>
            <li
              style={{
                paddingLeft: 26,
                paddingRight: 0,
              }}
              className={window.location.pathname == "/cities" ? "active" : ""}
            >
              <a href="/cities">Cities</a>
            </li>
            <li
              className={window.location.pathname == "/clients" ? "active" : ""}
              style={{
                paddingLeft: 26,
                paddingRight: 0,
              }}
            >
              <a href="/clients">
                {/* <i class="bx bxs-bar-chart-alt-2 option-icon"></i>  */}
                Clients
              </a>
            </li>
            <li
              className={window.location.pathname == "/csr" ? "active" : ""}
              style={{
                paddingLeft: 26,
                paddingRight: 0,
              }}
            >
              <a href="/csr">
                {/* <i class="bx bxs-bar-chart-alt-2 option-icon"></i>  */}
                CSR
              </a>
            </li>
            <li
              className={
                window.location.pathname == "/slider-images" ? "active" : ""
              }
              style={{
                paddingLeft: 26,
                paddingRight: 0,
              }}
            >
              <a href="/slider-images">Slider Images</a>
            </li>
            <li
              className={
                window.location.pathname == "/contact-masters" ? "active" : ""
              }
              style={{
                paddingLeft: 26,
                paddingRight: 0,
              }}
            >
              <a href="/contact-masters">Contact Masters</a>
            </li>
          </div>
        )}
        <li
          className={
            window.location.pathname == "/change-password" ? "active" : ""
          }
        >
          <a href="/change-password">
            <i class="bx bxs-bar-chart-alt-2 option-icon"></i> Change Password
          </a>
        </li>
      </div>
    );
  }
}
