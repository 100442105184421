import React, { Component } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { Card, Button } from "antd";
import AdsContent from "./homepage/AdsContent";
import ImageData from "./homepage/ImageData";
import VideoData from "./homepage/VideoData";

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      tableloader: false,
    };
  }

  render() {
    return (
      <div>
        <Header />
        <div className="content-wrapper">
          <div className="d-flex justify-content-between">
            <Sidebar />
            <div className="main-wrapper">
              <div className="inner">
                <div className="sidebar-space"></div>
                <div className="main-content">
                  <Card>
                    <Card>
                      <div className="d-flex justify-content-between">
                        <h3>Home Page Settings</h3>
                      </div>
                    </Card>
                    <br />
                    <div className="row">
                      <div className="col-lg-6">
                        <ImageData />
                      </div>
                      <div className="col-lg-6">
                        <AdsContent />
                      </div>
                      <div className="col-lg-6">
                        <VideoData />
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HomePage;
