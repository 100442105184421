import React from "react";
import "boxicons";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Redirect,
} from "react-router-dom";
import Login from "./app/Login";
import Dashboard from "./app/Dashboard";
import ChangePassword from "./app/ChangePassword";

import PostedJobsList from "./app/PostedJobsList";
import PostJob from "./app/PostJob";
import EditJob from "./app/EditJob";
import Internship from "./app/Internship";
import Resumes from "./app/Resumes";
import Clients from "./app/Clients";
import Csr from "./app/Csr";
import Manpower from "./app/Manpower";
import Contactus from "./app/Contactus";
import HomePage from "./app/web-settings/HomePage";
import States from "./app/web-settings/States";
import Cities from "./app/web-settings/Cities";
import Categories from "./app/web-settings/Categories";
import Services from "./app/Services";
import SliderImages from "./app/web-settings/SliderImages";
import Masters from "./app/web-settings/Masters";
import EditService from "./app/EditService";
import ContactList from "./app/ContactList";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" exact element={<Login />} />
          {/* Single pages  */}
          <Route path="/dashboard" exact element={<Dashboard />} />
          <Route path="/change-password" exact element={<ChangePassword />} />
          <Route path="/states" exact element={<States />} />
          <Route path="/cities" exact element={<Cities />} />

          <Route path="/posted-jobs" exact element={<PostedJobsList />} />
          <Route path="/post-new-job" exact element={<PostJob />} />
          <Route path="/internship-list" exact element={<Internship />} />
          <Route path="/resumes" exact element={<Resumes />} />
          <Route path="/clients" exact element={<Clients />} />
          <Route path="/csr" exact element={<Csr />} />
          <Route path="/manpower-enquiry" exact element={<Manpower />} />
          <Route path="/contact-us" exact element={<Contactus />} />
          <Route path="/home-page" exact element={<HomePage />} />
          <Route path="/categories" exact element={<Categories />} />
          <Route path="/services" exact element={<Services />} />
          <Route path="/slider-images" exact element={<SliderImages />} />
          <Route path="/edit-service" exact element={<EditService />} />
          <Route path="/masters" exact element={<Masters />} />
          <Route path="/contact-masters" exact element={<ContactList />} />
          <Route path="/edit-job" exact element={<EditJob />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
