import React, { Component } from "react";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import { Card, Table, Button, message } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import URLs from "./utils/apis";
import { BASE_URL } from "./utils/consts";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";
class Contactus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      tableloader: false,
    };
  }
  componentDidMount() {
    this.get_internship_list();
  }
  get_internship_list = () => {
    this.setState({ tableloader: true });
    axios
      .get(URLs.get_contact_list)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState({
            list: response.data.data,
            tableloader: false,
          });
        } else {
          this.setState({ tableloader: false });
        }
      })
      .catch((error) => {
        this.setState({ tableloader: false });
        console.error("Warning:", error);
      });
  };
  exportToCSV = () => {
    let arr = [];
    for (let item of this.state.list) {
      let photo_url = BASE_URL + "/" + item.photo;
      let resume_url = BASE_URL + "/" + item.resume;
      let obj = {
        "Full Name": item.name ? item.name : "---",
        Mobile: item.mobile ? item.mobile : "---",
        Email: item.email ? item.email : "---",
        "Intership For": item.intership_for ? item.intership_for : "---",
        "Current Location": item.current_location
          ? item.current_location
          : "---",
        "Photo URL": photo_url,
        "Resume URL": resume_url,
      };
      arr = [...arr, obj];
    }
    let fileName = "Resume List";
    const ws = XLSX.utils.json_to_sheet(arr);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    message.success("Data exported successfully.");
  };
  render() {
    const dataSource =
      this.state.list &&
      this.state.list.map((item, index) => {
        return {
          key: index,
          sr_no: Number(index + 1),
          name: item.name ? item.name : "---",
          mobile: item.mobile ? item.mobile : "---",
          email: item.email ? item.email : "---",
          city: item.city_name ? item.city_name : "---",
          state: item.state_name ? item.state_name : "---",
          message: item.message ? item.message : "---",
        };
      });
    const columns = [
      {
        title: "Sr No",
        dataIndex: "sr_no",
        key: "sr_no",
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Mobile",
        dataIndex: "mobile",
        key: "mobile",
      },
      {
        title: "City",
        dataIndex: "city",
        key: "city",
      },
      {
        title: "State",
        dataIndex: "state",
        key: "state",
      },
      {
        title: "Message",
        dataIndex: "message",
        key: "message",
      },
      // {
      //   title: "Action",
      //   dataIndex: "action",
      //   key: "action",
      // },
    ];
    return (
      <div>
        <Header />
        <div className="content-wrapper">
          <div className="d-flex justify-content-between">
            <Sidebar />
            <div className="main-wrapper">
              <div className="inner">
                <div className="sidebar-space"></div>
                <div className="main-content">
                  <div className="row">
                    <div className="col-lg-12">
                      <Card>
                        <Card>
                          <div className="d-flex justify-content-between">
                            <h3>
                              Contact List - (Total: {this.state.list.length})
                            </h3>
                          </div>
                        </Card>
                        <br />
                        <Card>
                          <Table dataSource={dataSource} columns={columns} />
                        </Card>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Contactus;
