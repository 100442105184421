import React, { Component } from "react";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import { Card, Table, Button, message } from "antd";
import { CloudDownloadOutlined } from "@ant-design/icons";
import axios from "axios";
import URLs from "./utils/apis";
import { BASE_URL } from "./utils/consts";
import moment from "moment";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

class Manpower extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      tableloader: false,
    };
  }
  componentDidMount() {
    this.get_category_list();
  }
  get_category_list = () => {
    this.setState({ tableloader: true });
    axios
      .get(URLs.gt_manpower_list)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState({
            list: response.data.data,
            tableloader: false,
          });
        } else {
          this.setState({ tableloader: false });
        }
      })
      .catch((error) => {
        this.setState({ tableloader: false });
        console.error("Warning:", error);
      });
  };
  downloadFile = (item) => {
    let url = BASE_URL + "/" + item.company_attachment;
    const newTab = window.open(url, "_blank");
    newTab.focus();
  };
  exportToCSV = () => {
    let arr = [];
    for (let item of this.state.list) {
      let obj = {
        "Full Name": item.name ? item.name : "---",
        Emal: item.email ? item.email : "---",
        Mobile: item.mobile ? item.mobile : "---",
        Designation: item.designation ? item.designation : "---",
        "Date Applied": item.date_created ? item.date_created : "---",
      };
      arr = [...arr, obj];
    }
    let fileName = "Manpower List";
    const ws = XLSX.utils.json_to_sheet(arr);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    message.success("Data exported successfully.");
  };
  render() {
    const dataSource =
      this.state.list &&
      this.state.list.map((item, index) => {
        return {
          key: index,
          sr_no: Number(index + 1),
          name: item.name,
          designation: item.designation,
          compnay: item.company_name ? item.company_name : "---",
          mobile: item.mobile ? item.mobile : "---",
          service_required: item,
          email: item.email ? item.email : "---",
          date_applied: item.date_created
            ? moment(item.date_created).format("DD-MM-YYYY")
            : "---",
          downloads: item,
        };
      });
    const columns = [
      {
        title: "Sr No",
        dataIndex: "sr_no",
        key: "sr_no",
        fixed: window.innerWidth > 650 ? "left" : false,
        width: 70,
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        fixed: window.innerWidth > 650 ? "left" : false,
      },
      {
        title: "Designation",
        dataIndex: "designation",
        key: "designation",
      },
      {
        title: "Company",
        dataIndex: "compnay",
        key: "compnay",
      },
      {
        title: "Mobile",
        dataIndex: "mobile",
        key: "mobile",
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Date Applied",
        dataIndex: "date_applied",
        key: "date_applied",
      },
      {
        title: "Service Required",
        dataIndex: "service_required",
        key: "service_required",

        render: (item) => {
          let service_required = item.service_required
            ? JSON.parse(item.service_required)
            : [];
          service_required = service_required.join(", ");
          return (
            <div>
              <p>{service_required}</p>
            </div>
          );
        },
      },
      {
        title: "Download Company Profile",
        dataIndex: "downloads",
        key: "downloads",
        fixed: window.innerWidth > 650 ? "right" : false,
        render: (item) => {
          return (
            <div>
              <Button type="primary" onClick={() => this.downloadFile(item)}>
                <CloudDownloadOutlined /> Download
              </Button>
            </div>
          );
        },
      },
    ];
    return (
      <div>
        <Header />
        <div className="content-wrapper">
          <div className="d-flex justify-content-between">
            <Sidebar />
            <div className="main-wrapper">
              <div className="inner">
                <div className="sidebar-space"></div>
                <div className="main-content">
                  <div className="row">
                    <div className="col-lg-12">
                      <Card>
                        <Card>
                          <div className="d-flex justify-content-between">
                            <h3>
                              Manpower Enquiry List - (Total:{" "}
                              {this.state.list.length})
                            </h3>
                            <Button
                              onClick={() => this.exportToCSV()}
                              type="primary"
                              icon={<CloudDownloadOutlined />}
                            >
                              Export
                            </Button>
                          </div>
                        </Card>
                        <br />
                        <Card>
                          <Table
                            loading={this.state.tableloader}
                            dataSource={dataSource}
                            columns={columns}
                            scroll={{ x: window.innerWidth > 650 ? 1400 : 300 }}
                          />
                        </Card>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Manpower;
