import React, { Component } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { Card, Button } from "antd";
import Location from "./masterComponents/Location";
import Department from "./masterComponents/Department";
import Role from "./masterComponents/Role";
import Industry from "./masterComponents/Industry";

class Masters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      tableloader: false,
    };
  }

  render() {
    return (
      <div>
        <Header />
        <div className="content-wrapper">
          <div className="d-flex justify-content-between">
            <Sidebar />
            <div className="main-wrapper">
              <div className="inner">
                <div className="sidebar-space"></div>
                <div className="main-content">
                  <Card>
                    <Card>
                      <div className="d-flex justify-content-between">
                        <h3>All Masters</h3>
                      </div>
                    </Card>
                    <br />
                    <div className="row">
                      <div className="col-lg-6">
                        <Location />
                      </div>
                      <div className="col-lg-6">
                        <Department />
                      </div>
                      <div className="col-lg-6">
                        <Role />
                      </div>
                      <div className="col-lg-6">
                        <Industry />
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Masters;
