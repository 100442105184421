import React, { Component } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { Card, Table, Button, message, Modal, Input, Spin } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import URLs from "../utils/apis";
import { BASE_URL } from "../utils/consts";

const { TextArea } = Input;

class SliderImages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      records: [],
      tableloader: false,
      addSliderModalStatus: false,
      image: "",
      updateSliderModalStatus: false,
      slider_id: 0,
      slider_img_url: "",
      edit_slider_img: "",
      deleteCsrModalStatus: false,
      formLoader: false,
    };
  }
  componentDidMount() {
    this.get_csr_list();
  }
  get_csr_list = () => {
    this.setState({ tableloader: true });
    axios
      .get(URLs.get_slider_image_list)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState({
            records: response.data.data,
            tableloader: false,
          });
        } else {
          this.setState({ tableloader: false });
        }
      })
      .catch((error) => {
        this.setState({ tableloader: false });
        console.error("Warning:", error);
      });
  };
  openAddModal = () => {
    this.setState({
      addSliderModalStatus: true,
    });
  };
  saveCSR = () => {
    if (!this.state.image) {
      message.warning("Please select CSR image");
      return;
    }
    this.setState({ formLoader: true });
    let formdata = new FormData();
    formdata.append("image", this.state.image);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    axios
      .post(URLs.add_new_slider, formdata, config)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState(
            {
              formLoader: false,
              addSliderModalStatus: false,
            },
            () => this.get_csr_list()
          );
          message.success(response.data.message);
          window.location.reload();
          console.log("response", response.data);
        } else {
          this.setState({ formLoader: false });
          message.warning(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ formLoader: false });
        message.warning("Please try again.");
      });
  };
  openEditModal = (item) => {
    let url = BASE_URL + "/" + item.image;
    this.setState({
      updateSliderModalStatus: true,
      slider_id: item.id,
      slider_img_url: url,
    });
  };
  updateCsr = () => {
    if (!this.state.edit_slider_img) {
      message.warning("Please select image");
      return;
    }
    this.setState({ formLoader: true });
    let formdata = new FormData();
    formdata.append("image", this.state.edit_slider_img);
    formdata.append("id", this.state.slider_id);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    axios
      .post(URLs.update_slider, formdata, config)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState(
            {
              formLoader: false,
              updateSliderModalStatus: false,
            },
            () => this.get_csr_list()
          );
          message.success(response.data.message);
          window.location.reload();
          console.log("response", response.data);
        } else {
          this.setState({ formLoader: false });
          message.warning(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ formLoader: false });
        message.warning("Please try again.");
      });
  };

  deleteClient = () => {
    let params = {
      id: this.state.slider_id,
    };
    axios
      .post(URLs.delete_slider, params)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState(
            {
              formLoader: false,
              deleteCsrModalStatus: false,
            },
            () => this.get_csr_list()
          );
          message.success(response.data.message);
        } else {
          this.setState({ formLoader: false });
          message.warning(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ formLoader: false });
        message.warning("Please try again.");
      });
  };
  openDeleteModal = (item) => {
    this.setState({
      deleteCsrModalStatus: true,
      slider_id: item.id,
    });
  };
  render() {
    const dataSource =
      this.state.records &&
      this.state.records.map((item, index) => {
        return {
          sr_no: Number(index + 1),
          slider_image: item,
          action: item,
        };
      });
    const columns = [
      {
        title: "Sr No",
        dataIndex: "sr_no",
        key: "sr_no",
      },
      {
        title: "Slider Image",
        dataIndex: "slider_image",
        key: "slider_image",
        render: (item) => {
          let url = BASE_URL + "/" + item.image;
          return (
            <div>
              <img src={url} style={{ maxWidth: 150 }} />
            </div>
          );
        },
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        render: (item) => {
          return (
            <div>
              <Button
                type="primary"
                className="success"
                onClick={() => this.openEditModal(item)}
              >
                Edit
              </Button>
              <Button
                type="primary"
                danger
                style={{ marginLeft: 10 }}
                onClick={() => this.openDeleteModal(item)}
              >
                Delete
              </Button>
            </div>
          );
        },
      },
    ];
    return (
      <div>
        <Header />
        <div className="content-wrapper">
          <div className="d-flex justify-content-between">
            <Sidebar />
            <div className="main-wrapper">
              <div className="inner">
                <div className="sidebar-space"></div>
                <div className="main-content">
                  <div className="row">
                    <div className="col-lg-12">
                      <Card>
                        <Card>
                          <div className="d-flex justify-content-between">
                            <h3>
                              Slider Image List - (Total:{" "}
                              {this.state.records.length})
                            </h3>
                            <Button
                              type="primary"
                              icon={<PlusCircleOutlined />}
                              onClick={() => this.openAddModal()}
                            >
                              Add Slider Image
                            </Button>
                          </div>
                        </Card>
                        <br />
                        <Card>
                          <Table
                            dataSource={dataSource}
                            loading={this.state.tableloader}
                            columns={columns}
                          />
                        </Card>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Add modal  */}
        <Modal
          visible={this.state.addSliderModalStatus}
          title="Add New SliderImage"
          okText="Save"
          cancelText="Cancel"
          onOk={() => this.saveCSR()}
          onCancel={() => this.setState({ addSliderModalStatus: false })}
        >
          <Spin spinning={this.state.formLoader}>
            <div className="mb-3 mt-5">
              <label style={{ fontSize: 16, paddingBottom: 6 }}>
                Select Image
              </label>
              <Input
                type="file"
                accept="image/*"
                onChange={(e) => this.setState({ image: e.target.files[0] })}
              />
            </div>
          </Spin>
        </Modal>

        {/* Edit modal  */}
        <Modal
          visible={this.state.updateSliderModalStatus}
          title="Edit Slider Image"
          okText="Update"
          cancelText="Cancel"
          onOk={() => this.updateCsr()}
          onCancel={() => this.setState({ updateSliderModalStatus: false })}
        >
          <Spin spinning={this.state.formLoader}>
            <div className="mb-5 mt-5">
              <label style={{ fontSize: 16, paddingBottom: 6 }}>
                Select Slider Image
              </label>
              {this.state.slider_img_url && (
                <div>
                  <a href={this.state.slider_img_url} target="_blank">
                    <img
                      src={this.state.slider_img_url}
                      style={{
                        maxWidth: 100,
                        borderRadius: 5,
                        marginBottom: 13,
                        padding: 4,
                        border: "1px solid #ddd",
                      }}
                    />
                  </a>
                </div>
              )}
              <Input
                type="file"
                accept="image/*"
                onChange={(e) =>
                  this.setState({ edit_slider_img: e.target.files[0] })
                }
              />
            </div>
          </Spin>
        </Modal>

        {/* Delete modal  */}
        <Modal
          visible={this.state.deleteCsrModalStatus}
          title="Delete Image"
          okText="Delete"
          cancelText="Cancel"
          onOk={() => this.deleteClient()}
          onCancel={() => this.setState({ deleteCsrModalStatus: false })}
        >
          <div className="mb-2 mt-4">
            <label style={{ fontSize: 18, paddingBottom: 6 }}>
              Are you sure you want to delete ?
            </label>
          </div>
        </Modal>
      </div>
    );
  }
}

export default SliderImages;
