import React, { Component } from "react";
import { Dropdown, Modal } from "antd";

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logoutModalStatus: false,
    };
  }
  componentDidMount() {
    this.checkForLogin();
  }
  checkForLogin = () => {
    if (!localStorage.getItem("admin_id")) {
      localStorage.removeItem("admin_id");
      localStorage.removeItem("admin_name");
      window.location.assign("/");
      return;
    }
  };
  showModal = () => {
    this.setState({
      logoutModalStatus: true,
    });
  };
  logout = () => {
    localStorage.removeItem("admin_id");
    localStorage.removeItem("admin_name");
    window.location.assign("/");
  };
  render() {
    const items = [
      {
        label: (
          <a href="#!" onClick={() => this.showModal()}>
            Logout
          </a>
        ),
        key: "0",
      },
    ];
    return (
      <div className="header-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6">
              <a href="/dashboard" class="logo">
                <img
                  src="./assets/images/bluechipjobs.jpg"
                  height="100%"
                  width="100%"
                  alt=""
                  className="logo"
                />
                <p>Bluechip Jobs Admin Panel</p>
              </a>
            </div>
            <div className="col-lg-4"></div>
            <div className="col-lg-2">
              <div>
                <div>
                  <Dropdown
                    menu={{
                      items,
                    }}
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <img
                        src="./assets/images/user.png"
                        style={{ maxWidth: 60, cursor: "pointer" }}
                      />
                    </a>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          visible={this.state.logoutModalStatus}
          okText="Yes"
          cancelText="No"
          onOk={() => this.logout()}
          onCancel={() => this.setState({ logoutModalStatus: false })}
        >
          <p style={{ fontSize: 18, padding: "20px" }}>
            Are you sure you want to logout ?
          </p>
        </Modal>
      </div>
    );
  }
}
