import React, { Component } from "react";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import { Card, Table, Button, Menu, Dropdown, Switch } from "antd";
import { CloudDownloadOutlined, PlusCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import URLs from "./utils/apis";
import moment from "moment";
import EditJob from "./job/EditJob";
import ViewAppliedJobs from "./job/ViewAppliedJobs";

class PostedJobsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      tableloader: false,
    };
  }
  componentDidMount() {
    this.get_data();
  }
  get_data = () => {
    this.setState({ tableloader: true });
    axios
      .post(URLs.job_post_list)
      .then((response) => {
        if (response.data.status == "1") {
          console.log("response", response);
          this.setState({
            tableloader: false,
            list: response.data.data,
          });
        } else {
          this.setState({ tableloader: false });
        }
      })
      .catch((error) => {
        this.setState({ tableloader: false });
        console.error("Warning:", error);
      });
  };
  onChange = (checked, item) => {
    console.log(`switch to ${checked}`);
    this.setState({ tableloader: true });
    let params = {
      job_id: item.job_id,
      status: checked == true ? "active" : "inactive",
    };
    axios
      .post(URLs.update_post_status, params)
      .then((response) => {
        if (response.data.status == "1") {
          console.log("response", response);
          this.setState(
            {
              tableloader: false,
            },
            () => this.get_data()
          );
        } else {
          this.setState({ tableloader: false });
        }
      })
      .catch((error) => {
        this.setState({ tableloader: false });
        console.error("Warning:", error);
      });
  };
  update_status = () => {};
  render() {
    const dataSource =
      this.state.list &&
      this.state.list.map((item, index) => {
        return {
          key: index,
          position: item.position,
          job_posted: item,
          applied_jobs: item,
          no_of_posts: item.no_of_posts + " Seats",
          experience:
            item.experience_from + " Yrs to " + item.experience_to + " Yrs",
          location: item.location,
          date_posted: item.date_posted
            ? moment(item.date_posted).format("DD MMM, YYYY")
            : "---",
          action: item,
          status: item,
        };
      });
    const columns = [
      {
        title: "Position",
        dataIndex: "position",
        key: "position",
      },
      {
        title: "Date Posted",
        dataIndex: "date_posted",
        key: "date_posted",
      },
      {
        title: "Not Of Posts",
        dataIndex: "no_of_posts",
        key: "no_of_posts",
      },
      {
        title: "Experience",
        dataIndex: "experience",
        key: "experience",
      },
      {
        title: "Location",
        dataIndex: "location",
        key: "location",
      },
      {
        title: "Applied Jobs",
        dataIndex: "applied_jobs",
        key: "applied_jobs",
        render: (item) => {
          return (
            <div>
              <ViewAppliedJobs data={item} />
            </div>
          );
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (item) => {
          return (
            <Switch
              checked={item.status == "active" ? true : false}
              onChange={(e) => this.onChange(e, item)}
            />
          );
        },
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        render: (item) => {
          const menu = (
            <Menu className="action-menu participant-action-menu">
              {/* <EditJob data={item} /> */}
              <Menu.Item key={`job_status_${item.index}`}>
                <a
                  className="action-buttons"
                  href={`/edit-job?id=${item.job_id}`}
                >
                  <i
                    className="fas fa-file-alt"
                    style={{ color: "#000", fontSize: 18 }}
                  ></i>
                  <span style={{ fontSize: 14, marginLeft: 10 }}>Edit Job</span>
                </a>
              </Menu.Item>
            </Menu>
          );
          return (
            <div style={{ width: "100%" }}>
              <Dropdown overlay={menu}>
                <a
                  className="ant-dropdown-link action-link"
                  onClick={(e) => e.preventDefault()}
                >
                  <box-icon
                    name="dots-vertical"
                    color="#000"
                    size={"19px"}
                    style={{
                      background: "#ddd",
                      padding: "5px",
                      borderRadius: 3,
                    }}
                  ></box-icon>
                </a>
              </Dropdown>
            </div>
          );
        },
      },
    ];
    return (
      <div>
        <Header />
        <div className="content-wrapper">
          <div className="d-flex justify-content-between">
            <Sidebar />
            <div className="main-wrapper">
              <div className="inner">
                <div className="sidebar-space"></div>
                <div className="main-content">
                  <div className="row">
                    <div className="col-lg-12">
                      <Card>
                        <Card>
                          <div className="d-flex justify-content-between">
                            <h3>
                              Posted Job List - (Total: {this.state.list.length}
                              )
                            </h3>
                            <div>
                              <Button
                                onClick={() =>
                                  window.location.assign("/post-new-job")
                                }
                                type="primary"
                                icon={<PlusCircleOutlined />}
                              >
                                Post Job
                              </Button>{" "}
                              {/* <Button
                                type="primary"
                                icon={<CloudDownloadOutlined />}
                              >
                                Export
                              </Button> */}
                            </div>
                          </div>
                        </Card>
                        <br />
                        <Card>
                          <Table dataSource={dataSource} columns={columns} />
                        </Card>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PostedJobsList;
