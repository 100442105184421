import React, { Component } from "react";
import { Menu, message, Spin, Modal, Input, Select, Button } from "antd";
import axios from "axios";
import URLs from "../utils/apis";
import FullEditor from "ckeditor5-build-full";
import { CKEditor } from "@ckeditor/ckeditor5-react";

const { Option } = Select;
const { TextArea } = Input;

export default class EditJob extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalStatus: false,
      single: {},
      formLoader: false,

      no_of_posts: [],
      experience_list: [],
      ctc_list: [
        "0-3 Lakhs",
        "3-6 Lakhs",
        "6-10 Lakhs",
        "10-15 Lakhs",
        "15-25 Lakhs",
        "25-50 Lakhs",
        "50-75 Lakhs",
        "75-100 Lakhs",
      ],
      job_id: "",
      position: "",
      about_company: "",
      date_posted: "",
      no_of_post: 1,
      job_description: "",
      candidate_profile: "",
      experience_from: "",
      experience_to: "",
      qualification: "",
      location_avaiable: "",
      ctc_from: "",
      ctc_to: "",
      department_id: "",
      company_name: "",
      industry_id: "",
      role_id: "",
      employment_type: "",

      department_list: [],
      industry_list: [],
      role_list: [],
      employmenttype_list: ["Full Time", "Part Time", "Permanent"],
      short_job_description: "",
    };
  }
  get_list = () => {
    axios
      .get(URLs.get_job_select_list)
      .then((response) => {
        if (response.data.status == "1") {
          console.log("response", response);
          this.setState({
            department_list: response.data.data.department,
            industry_list: response.data.data.industry,
            role_list: response.data.data.role,
          });
        }
      })
      .catch((error) => {
        this.setState({ formLoader: false });
        message.warning("Please try again.");
        console.error("Warning:", error);
      });
  };
  proccess_data = () => {
    let no_of_posts = [];
    for (let i = 1; i <= 400; i++) {
      no_of_posts.push(i);
    }
    let experi_list = [];
    for (let i = 0; i <= 30; i++) {
      experi_list.push(i);
    }
    experi_list.push("Any");
    this.setState({
      no_of_posts,
      experience_list: experi_list,
    });
  };
  updateJob = () => {
    if (!this.state.position) {
      message.warning("Position is required.");
      return;
    }
    if (!this.state.date_posted) {
      message.warning("Date posted value is required.");
      return;
    }
    if (!this.state.no_of_post) {
      message.warning("Please select no of post.");
      return;
    }
    if (!this.state.company_name) {
      message.warning("Please enter name of company");
      return;
    }
    if (!this.state.about_company) {
      message.warning("Please enter info of about company");
      return;
    }
    if (!this.state.job_description) {
      message.warning("Please enter info of job description");
      return;
    }
    if (!this.state.short_job_description) {
      message.warning("Please enter info of short job description");
      return;
    }

    if (!this.state.candidate_profile) {
      message.warning("Please enter info of candidate profile");
      return;
    }
    if (!this.state.experience_from && this.state.experience_from != "0") {
      message.warning("Please select experience from");
      return;
    }
    if (!this.state.experience_to && this.state.experience_to != "0") {
      message.warning("Please select experience to");
      return;
    }
    if (!this.state.qualification) {
      message.warning("Qualification is rquired.");
      return;
    }
    if (!this.state.location_avaiable) {
      message.warning("Location is required.");
      return;
    }
    if (!this.state.ctc_from && this.state.ctc_from != "0") {
      message.warning("Please select CTC from");
      return;
    }
    if (!this.state.ctc_to && this.state.ctc_to != "0") {
      message.warning("Please select CTC to");
      return;
    }
    if (!this.state.department_id) {
      message.warning("Please select department type");
      return;
    }
    if (!this.state.industry_id) {
      message.warning("Please select industry type");
      return;
    }
    if (!this.state.employment_type) {
      message.warning("Please select employment type");
      return;
    }
    if (!this.state.role_id) {
      message.warning("Please select role type");
      return;
    }
    let params = {
      position: this.state.position, //done
      date_posted: this.state.date_posted, //done
      no_of_posts: this.state.no_of_post, //done
      about_company: JSON.stringify(this.state.about_company), //done
      job_description: JSON.stringify(this.state.job_description), //done
      short_job_description: this.state.short_job_description, //done
      candidate_profile: this.state.candidate_profile, //done
      experience_from: this.state.experience_from, //done
      experience_to: this.state.experience_to, //done
      qualification: this.state.qualification, //done
      location: this.state.location_avaiable, //done
      ctc_from: this.state.ctc_from, //done
      ctc_to: this.state.ctc_to, //done
      company_name: this.state.company_name, //done
      department_id: this.state.department_id, //done
      role_id: this.state.role_id, //done
      employment_type: this.state.employment_type, //done
      industry_id: this.state.industry_id, //done
      job_id: this.state.job_id,
    };
    this.setState({ formLoader: true });
    axios
      .post(URLs.update_post, params)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState(
            {
              formLoader: false,
              modalStatus: false,
            },
            () => {
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            }
          );
        } else {
          message.warning(response.data.message);
          this.setState({ formLoader: false });
        }
      })
      .catch((error) => {
        this.setState({ formLoader: false });
        message.warning("Please try again.");
        console.error("Warning:", error);
      });
  };
  componentDidMount() {
    console.log("edit details", this.props);
    this.proccess_data();
    this.get_list();
  }
  show_modal = () => {
    let item = this.props.data;
    this.setState({
      job_id: item.job_id,
      position: item.position,
      about_company: JSON.parse(item.about_company),
      date_posted: item.date_posted,
      no_of_post: 1,
      job_description: JSON.parse(item.job_description),
      short_job_description: item.short_job_description,
      candidate_profile: item.candidate_profile,
      experience: item.experience,
      qualification: item.qualification,
      location_avaiable: item.location,
      ctc: item.ctc,
      department_id: item.department_id,
      company_name: item.company_name,
      industry_id: item.industry_id,
      role_id: item.role_id,
      employment_type: item.employment_type,
      modalStatus: true,
    });
  };
  update = () => {};
  closeModal = () => {
    this.setState({
      modalStatus: false,
    });
  };
  render() {
    return (
      <div>
        <Menu.Item key={`edit_job_${this.props.data.index}`}>
          <a
            className="action-buttons"
            href="#!"
            onClick={() => this.show_modal()}
          >
            <i
              className="fas fa-file-alt"
              style={{ color: "#000", fontSize: 18 }}
            ></i>
            <span style={{ fontSize: 14, marginLeft: 10 }}>Edit Job</span>
          </a>
        </Menu.Item>

        <Modal
          title="Update Post Job"
          visible={this.state.modalStatus}
          okText={"Update"}
          cancelText={"Cancel"}
          onOk={() => this.updateJob()}
          onCancel={() => this.closeModal()}
          width={500}
        >
          <Spin spinning={this.state.formLoader}>
            <div className="row">
              <div className="col-lg-12">
                <div className="mb-3 mt-2">
                  <label style={{ fontSize: 16, paddingBottom: 6 }}>
                    Position <span className="text-danger">*</span>
                  </label>
                  <Input
                    type="text"
                    value={this.state.position}
                    onChange={(e) =>
                      this.setState({
                        position: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="mb-3 mt-2">
                  <label style={{ fontSize: 16, paddingBottom: 6 }}>
                    Date Posted <span className="text-danger">*</span>
                  </label>
                  <Input
                    type="date"
                    value={this.state.date_posted}
                    onChange={(e) =>
                      this.setState({
                        date_posted: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="mb-3 mt-2">
                  <label style={{ fontSize: 16, paddingBottom: 6 }}>
                    No of Posts <span className="text-danger">*</span>
                  </label>
                  <Select
                    style={{
                      width: "100%",
                    }}
                    value={this.state.no_of_post}
                    onChange={(e) => this.setState({ no_of_post: e })}
                  >
                    <Option value="">Select</Option>
                    {this.state.no_of_posts.map((item, index) => {
                      return (
                        <Option value={item} key={index}>
                          {item}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
                <div className="mb-3 mt-2">
                  <label style={{ fontSize: 16, paddingBottom: 6 }}>
                    Company Name <span className="text-danger">*</span>
                  </label>
                  <Input
                    type="text"
                    value={this.state.company_name}
                    onChange={(e) =>
                      this.setState({
                        company_name: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="mb-3 mt-2">
                  <label style={{ fontSize: 16, paddingBottom: 6 }}>
                    About Company <span className="text-danger">*</span>
                  </label>
                  <CKEditor
                    data={this.state.about_company}
                    editor={FullEditor}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      this.setState({ about_company: data });
                    }}
                  />
                </div>
                <div className="mb-3 mt-2">
                  <label style={{ fontSize: 16, paddingBottom: 6 }}>
                    Job Description <span className="text-danger">*</span>
                  </label>
                  <CKEditor
                    data={this.state.job_description}
                    editor={FullEditor}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      this.setState({ job_description: data });
                    }}
                  />
                </div>
                <div className="mb-3 mt-2">
                  <label style={{ fontSize: 16, paddingBottom: 6 }}>
                    Short Job Description <span className="text-danger">*</span>
                  </label>
                  <TextArea
                    placeholder=""
                    onChange={(e) =>
                      this.setState({
                        short_job_description: e.target.value,
                      })
                    }
                    value={this.state.short_job_description}
                  />
                </div>
                <div className="mb-3 mt-2">
                  <label style={{ fontSize: 16, paddingBottom: 6 }}>
                    Candidates Profile <span className="text-danger">*</span>
                  </label>
                  <TextArea
                    placeholder=""
                    onChange={(e) =>
                      this.setState({
                        candidate_profile: e.target.value,
                      })
                    }
                    value={this.state.candidate_profile}
                  />
                </div>
                <div className="mb-3 mt-2">
                  <label style={{ fontSize: 16, paddingBottom: 6 }}>
                    Experience Required (In Years){" "}
                    <span className="text-danger">*</span>
                  </label>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Select
                      style={{
                        width: "48%",
                      }}
                      value={this.state.experience_from}
                      onChange={(e) => this.setState({ experience_from: e })}
                    >
                      <Option value="">From</Option>
                      {this.state.experience_list.length > 0 &&
                        this.state.experience_list.map((item, index) => {
                          return (
                            <Option value={item} key={index}>
                              {item}
                            </Option>
                          );
                        })}
                    </Select>
                    <Select
                      style={{
                        width: "48%",
                      }}
                      value={this.state.experience_to}
                      onChange={(e) => this.setState({ experience_to: e })}
                    >
                      <Option value="">To</Option>
                      {this.state.experience_list.length > 0 &&
                        this.state.experience_list.map((item, index) => {
                          return (
                            <Option value={item} key={index}>
                              {item}
                            </Option>
                          );
                        })}
                    </Select>
                  </div>
                </div>
                <div className="mb-3 mt-2">
                  <label style={{ fontSize: 16, paddingBottom: 6 }}>
                    Qualification <span className="text-danger">*</span>
                  </label>
                  <Input
                    type="text"
                    value={this.state.qualification}
                    onChange={(e) =>
                      this.setState({
                        qualification: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="mb-3 mt-2">
                  <label style={{ fontSize: 16, paddingBottom: 6 }}>
                    Location Available <span className="text-danger">*</span>
                  </label>
                  <Input
                    type="text"
                    value={this.state.location_avaiable}
                    onChange={(e) =>
                      this.setState({
                        location_avaiable: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="mb-3 mt-2">
                  <label style={{ fontSize: 16, paddingBottom: 6 }}>
                    CTC (Lakhs) <span className="text-danger">*</span>
                  </label>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Select
                      style={{
                        width: "48%",
                      }}
                      value={this.state.ctc_from}
                      onChange={(e) => {
                        let value = e;
                        this.setState(
                          {
                            ctc_from: e,
                          },
                          () => {
                            if (value == "negotiable") {
                              this.setState({
                                ctc_to: "",
                              });
                            }
                          }
                        );
                      }}
                    >
                      <Option value="">Select</Option>
                      <Option value="negotiable">Negotiable</Option>
                      {this.state.experience_list.map((item, index) => {
                        return (
                          <Option value={item} key={index}>
                            {item}
                          </Option>
                        );
                      })}
                      <Option value="100+">100+</Option>
                    </Select>
                    <Select
                      style={{
                        width: "48%",
                      }}
                      value={this.state.ctc_to}
                      onChange={(e) => this.setState({ ctc_to: e })}
                      disabled={
                        this.state.ctc_from == "negotiable" ? true : false
                      }
                    >
                      <Option value="">Select</Option>
                      {this.state.experience_list.map((item, index) => {
                        return (
                          <Option value={item} key={index}>
                            {item}
                          </Option>
                        );
                      })}
                      <Option value="100+">100+</Option>
                    </Select>
                  </div>
                </div>
                <div className="mb-3 mt-2">
                  <label style={{ fontSize: 16, paddingBottom: 6 }}>
                    Department Type <span className="text-danger">*</span>
                  </label>
                  <Select
                    style={{
                      width: "100%",
                    }}
                    value={this.state.department_id}
                    onChange={(e) => this.setState({ department_id: e })}
                  >
                    <Option value="">Select</Option>
                    {this.state.department_list &&
                      this.state.department_list.map((item, index) => {
                        return (
                          <Option value={item.department_id} key={index}>
                            {item.d_name}
                          </Option>
                        );
                      })}
                  </Select>
                </div>
                <div className="mb-3 mt-2">
                  <label style={{ fontSize: 16, paddingBottom: 6 }}>
                    Industry Type <span className="text-danger">*</span>
                  </label>
                  <Select
                    style={{
                      width: "100%",
                    }}
                    value={this.state.industry_id}
                    onChange={(e) => this.setState({ industry_id: e })}
                  >
                    <Option value="">Select</Option>
                    {this.state.industry_list &&
                      this.state.industry_list.map((item, index) => {
                        return (
                          <Option value={item.industry_id} key={index}>
                            {item.industry_name}
                          </Option>
                        );
                      })}
                  </Select>
                </div>
                <div className="mb-3 mt-2">
                  <label style={{ fontSize: 16, paddingBottom: 6 }}>
                    Employment Type <span className="text-danger">*</span>
                  </label>
                  <Select
                    style={{
                      width: "100%",
                    }}
                    value={this.state.employment_type}
                    onChange={(e) => this.setState({ employment_type: e })}
                  >
                    <Option value="">Select</Option>
                    {this.state.employmenttype_list &&
                      this.state.employmenttype_list.map((item, index) => {
                        return (
                          <Option value={item} key={index}>
                            {item}
                          </Option>
                        );
                      })}
                  </Select>
                </div>
                <div className="mb-3 mt-2">
                  <label style={{ fontSize: 16, paddingBottom: 6 }}>
                    Role Type <span className="text-danger">*</span>
                  </label>
                  <Select
                    style={{
                      width: "100%",
                    }}
                    value={this.state.role_id}
                    onChange={(e) => this.setState({ role_id: e })}
                  >
                    <Option value="">Select</Option>
                    {this.state.role_list &&
                      this.state.role_list.map((item, index) => {
                        return (
                          <Option value={item.role_id} key={index}>
                            {item.role_name}
                          </Option>
                        );
                      })}
                  </Select>
                </div>
              </div>
            </div>
          </Spin>
        </Modal>
      </div>
    );
  }
}
