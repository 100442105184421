import React, { Component } from "react";
import { Card, Spin, Button, Input, message } from "antd";
import axios from "axios";
import URLs from "../utils/apis";

const { TextArea } = Input;

class ContactDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formLoader: "",

      working_hours: "",
      holidays: "",
    };
  }
  componentDidMount() {
    this.get_contact_details();
  }
  get_contact_details = () => {
    this.setState({ formLoader: true });
    axios
      .get(URLs.get_contact_details)
      .then((response) => {
        if (response.data.status == "1") {
          if (response.data.data.length > 0) {
            this.setState({
              working_hours: response.data.data[0].working_hours,
              holidays: response.data.data[0].holidays,
              formLoader: false,
            });
          }
        } else {
          this.setState({ formLoader: false });
        }
      })
      .catch((error) => {
        this.setState({ formLoader: false });
        console.error("Warning:", error);
      });
  };
  update_contact_details = () => {
    if (!this.state.working_hours) {
      message.warning("Working hours is required");
      return;
    }
    if (!this.state.holidays) {
      message.warning("Holiday value is required");
      return;
    }
    this.setState({ formLoader: false });
    let params = {
      working_hours: this.state.working_hours,
      holidays: this.state.holidays,
    };
    axios
      .post(URLs.update_contact_details, params)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState(
            {
              formLoader: false,
              category_name: "",
              addCsrModalStatus: false,
            },
            () => this.get_contact_details()
          );
          message.success(response.data.message);
        } else {
          this.setState({ formLoader: false });
          message.warning(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ formLoader: false });
        message.warning("Please try again.");
      });
  };
  render() {
    return (
      <div>
        <div className="row">
          <div className="col-lg-12">
            <Spin spinning={this.state.formLoader}>
              <Card>
                <Card>
                  <div className="d-flex justify-content-between">
                    <h3>Contact Details</h3>
                  </div>
                </Card>
                <br />
                <Card>
                  <div className="mb-3 mt-2">
                    <label style={{ fontSize: 16, paddingBottom: 6 }}>
                      Working Hours <span className="text-danger">*</span>
                    </label>
                    <Input
                      type="text"
                      value={this.state.working_hours}
                      onChange={(e) =>
                        this.setState({ working_hours: e.target.value })
                      }
                    />
                  </div>
                  <div className="mb-4">
                    <label style={{ fontSize: 16, paddingBottom: 6 }}>
                      Holidays <span className="text-danger">*</span>
                    </label>
                    <Input
                      type="text"
                      value={this.state.holidays}
                      onChange={(e) =>
                        this.setState({ holidays: e.target.value })
                      }
                    />
                  </div>
                  <Button
                    type="primary"
                    onClick={() => this.update_contact_details()}
                  >
                    Update
                  </Button>
                </Card>
              </Card>
            </Spin>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactDetails;
