import React, { Component } from "react";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import { Card, Table, Button, Modal, Input, message, Spin } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import URLs from "./utils/apis";
import FullEditor from "ckeditor5-build-full";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { BASE_URL } from "./utils/consts";
import parse from "html-react-parser";

const { TextArea } = Input;

class EditService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      tableloader: false,
      addCsrModalStatus: false,
      updateCategoryModalStatus: false,
      records: [],
      formLoader: false,
      edit_category_id: "",
      deleteCsrModalStatus: false,
      edit_category_name: "",
      singleData: {},
      view_description: "",
      view_short_image: "",
      view_long_image: "",

      service_name: "",
      title: "",
      banner_img: "",
      small_img: "",
      description: "",
      id: "0",
      banner_img_url: "",
      small_img_url: "",
    };
  }
  componentDidMount() {
    var url = new URL(window.location.href);
    // Get the search parameters
    var params = new URLSearchParams(url.search);
    // Get a specific parameter value
    var id = params.get("id");
    if (id) {
      this.setState(
        {
          id: id,
        },
        () => this.get_service_list()
      );
    } else {
      setTimeout(() => {
        window.location.href = "/services";
      }, 2000);
    }
  }
  get_service_list = () => {
    this.setState({ tableloader: true });
    axios
      .get(URLs.get_service_list)
      .then((response) => {
        console.log("response", response.data);

        if (response.data.status == "1") {
          for (let item of response.data.data) {
            if (item.service_id == this.state.id) {
              let banner_img = BASE_URL + "/" + item.banner_img;
              let small_img = BASE_URL + "/" + item.small_img;
              this.setState({
                service_name: item.service_name,
                title: item.title,
                banner_img_url: banner_img,
                small_img_url: small_img,
                description: item.description,
              });
              return;
            }
          }
          this.setState({
            records: response.data.data,
            tableloader: false,
          });
        } else {
          this.setState({ tableloader: false });
        }
      })
      .catch((error) => {
        this.setState({ tableloader: false });
        console.error("Warning:", error);
      });
  };
  openAddModal = () => {
    this.setState({
      addCsrModalStatus: true,
    });
  };
  openEditModal = (item) => {
    let view_short_image = BASE_URL + "/" + item.small_img;
    let view_long_image = BASE_URL + "/" + item.banner_img;
    this.setState({
      updateCategoryModalStatus: true,
      edit_category_id: item.category_id,
      edit_category_name: item.name,
      singleData: item,
      view_description: item.description,
      view_short_image,
      view_long_image,
    });
  };
  updateService = () => {
    if (!this.state.service_name) {
      message.warning("Service name is required");
      return;
    }
    if (!this.state.title) {
      message.warning("Title is required");
      return;
    }
    if (!this.state.description) {
      message.warning("Description is required");
      return;
    }
    this.setState({ formLoader: false });
    let formData = new FormData();
    formData.append("id", this.state.id);
    formData.append("title", this.state.title);
    formData.append("name", this.state.service_name);
    formData.append("description", this.state.description);
    formData.append("small_img", this.state.small_img);
    formData.append("banner_img", this.state.banner_img);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    this.setState({ formLoader: true });
    axios
      .post(URLs.update_service, formData, config)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState(
            {
              formLoader: false,
            },
            () => window.location.assign("/services")
          );
        } else {
          this.setState({ formLoader: false });
          message.warning(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ formLoader: false });
        message.warning("Please try again.");
      });
  };
  render() {
    return (
      <div>
        <Header />
        <div className="content-wrapper">
          <div className="d-flex justify-content-between">
            <Sidebar />
            <div className="main-wrapper">
              <div className="inner">
                <div className="sidebar-space"></div>
                <div className="main-content">
                  <div className="row">
                    <div className="col-lg-12">
                      <Card>
                        <Card>
                          <div className="d-flex justify-content-between">
                            <h3>Edit Service</h3>
                          </div>
                        </Card>
                        <br />
                        <Card>
                          <div className="row">
                            <div className="col-lg-8">
                              <Spin spinning={this.state.formLoader}>
                                <div className="mb-3">
                                  <label
                                    style={{ fontSize: 16, paddingBottom: 6 }}
                                  >
                                    Service Name
                                  </label>
                                  <Input
                                    type="text"
                                    value={this.state.service_name}
                                    onChange={(e) =>
                                      this.setState({
                                        service_name: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                                <div className="mb-3">
                                  <label
                                    style={{ fontSize: 16, paddingBottom: 6 }}
                                  >
                                    Title
                                  </label>
                                  <Input
                                    type="text"
                                    value={this.state.title}
                                    onChange={(e) =>
                                      this.setState({ title: e.target.value })
                                    }
                                  />
                                </div>
                                <div className="mb-3">
                                  <label
                                    style={{ fontSize: 16, paddingBottom: 6 }}
                                  >
                                    Description
                                  </label>
                                  <CKEditor
                                    data={this.state.description}
                                    editor={FullEditor}
                                    onChange={(event, editor) => {
                                      const data = editor.getData();
                                      this.setState({ description: data });
                                    }}
                                  />
                                </div>
                                <div className="mb-3">
                                  <label
                                    style={{ fontSize: 16, paddingBottom: 6 }}
                                  >
                                    Select Small Image
                                  </label>
                                  {this.state.small_img_url && (
                                    <div>
                                      <img
                                        src={this.state.small_img_url}
                                        style={{ maxWidth: 150 }}
                                      />
                                      <br />
                                    </div>
                                  )}
                                  <Input
                                    type="file"
                                    onChange={(e) =>
                                      this.setState({
                                        small_img: e.target.files[0],
                                      })
                                    }
                                    accept="image/*"
                                  />
                                </div>
                                <div className="mb-3">
                                  <label
                                    style={{ fontSize: 16, paddingBottom: 6 }}
                                  >
                                    Select Banner Image
                                  </label>
                                  {this.state.banner_img_url && (
                                    <div>
                                      <img
                                        src={this.state.banner_img_url}
                                        style={{ maxWidth: 150 }}
                                      />
                                      <br />
                                    </div>
                                  )}
                                  <Input
                                    type="file"
                                    onChange={(e) =>
                                      this.setState({
                                        banner_img: e.target.files[0],
                                      })
                                    }
                                    accept="image/*"
                                  />
                                </div>

                                <Button
                                  type="primary"
                                  onClick={() => this.updateService()}
                                >
                                  Update Service
                                </Button>
                              </Spin>
                            </div>
                          </div>
                        </Card>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditService;
