let BASE_URL = "https://api.bluechipjobs.co.in";
let URLs = {
  login: BASE_URL + "/admin/login",
  change_password: BASE_URL + "/admin/change-password",
  get_dashboard_data: BASE_URL + "/admin/dashboard/get",
  get_header_data: BASE_URL + "/admin/masters/get_header_content_data",
  update_header_data: BASE_URL + "/admin/masters/update_header_content_data",
  // clients
  get_client_list: BASE_URL + "/admin/client/get",
  add_new_client: BASE_URL + "/admin/client/add",
  update_client: BASE_URL + "/admin/client/update",
  delete_client: BASE_URL + "/admin/client/delete",
  // csr
  get_csr_list: BASE_URL + "/admin/csr/get",
  add_new_csr: BASE_URL + "/admin/csr/add",
  update_csr: BASE_URL + "/admin/csr/update",
  delete_csr: BASE_URL + "/admin/csr/delete",
  // categories
  get_category_list: BASE_URL + "/admin/category/get",
  add_new_category: BASE_URL + "/admin/category/add",
  update_category: BASE_URL + "/admin/category/update",
  delete_category: BASE_URL + "/admin/category/delete",
  // service
  get_service_list: BASE_URL + "/admin/service/get",
  add_new_service: BASE_URL + "/admin/service/add",
  update_service: BASE_URL + "/admin/service/update",
  // get maopowet
  gt_manpower_list: BASE_URL + "/admin/manpower-enquiry/get",
  // slider images
  get_slider_image_list: BASE_URL + "/admin/slider-image/get",
  add_new_slider: BASE_URL + "/admin/slider-image/add",
  update_slider: BASE_URL + "/admin/slider-image/update",
  delete_slider: BASE_URL + "/admin/slider-image/delete",
  forgot_password: BASE_URL + "/admin/forgot-password",

  add_new_job_post: BASE_URL + "/admin/job-post/add",
  job_post_list: BASE_URL + "/admin/job-post/list",
  update_post: BASE_URL + "/admin/job-post/update",
  update_post_status: BASE_URL + "/admin/job-post/update_status",
  delete_post: BASE_URL + "/admin/job-post/delete",
  get_job_select_list: BASE_URL + "/admin/job-post/get_lists",

  get_resume_list: BASE_URL + "/admin/resume/list",
  get_internship_list: BASE_URL + "/admin/internship/list",
  get_contact_list: BASE_URL + "/admin/contact/list",
  get_applied_job_list: BASE_URL + "/admin/applied_jobs/list",

  // location
  get_location_list: BASE_URL + "/admin/location/get",
  add_new_location: BASE_URL + "/admin/location/add",
  update_location: BASE_URL + "/admin/location/update",
  delete_location: BASE_URL + "/admin/location/delete",

  // department
  get_department_list: BASE_URL + "/admin/department/get",
  add_new_department: BASE_URL + "/admin/department/add",
  update_department: BASE_URL + "/admin/department/update",
  delete_department: BASE_URL + "/admin/department/delete",

  // role
  get_role_list: BASE_URL + "/admin/role/get",
  add_new_role: BASE_URL + "/admin/role/add",
  update_role: BASE_URL + "/admin/role/update",
  delete_role: BASE_URL + "/admin/role/delete",

  // industry
  get_industry_list: BASE_URL + "/admin/industry/get",
  add_new_industry: BASE_URL + "/admin/industry/add",
  update_industry: BASE_URL + "/admin/industry/update",
  delete_industry: BASE_URL + "/admin/industry/delete",

  // state
  get_state_list: BASE_URL + "/admin/state/get",
  add_new_state: BASE_URL + "/admin/state/add",
  update_state: BASE_URL + "/admin/state/update",
  delete_state: BASE_URL + "/admin/state/delete",

  get_city_list: BASE_URL + "/admin/city/get",
  add_new_city: BASE_URL + "/admin/city/add",
  update_city: BASE_URL + "/admin/city/update",
  delete_city: BASE_URL + "/admin/city/delete",

  get_email_list: BASE_URL + "/admin/email/get",
  add_new_email: BASE_URL + "/admin/email/add",
  update_email: BASE_URL + "/admin/email/update",
  delete_email: BASE_URL + "/admin/email/delete",

  get_office_list: BASE_URL + "/admin/office/get",
  add_new_office: BASE_URL + "/admin/office/add",
  update_office: BASE_URL + "/admin/office/update",
  delete_office: BASE_URL + "/admin/office/delete",

  get_contact_details: BASE_URL + "/admin/contact/get",
  update_contact_details: BASE_URL + "/admin/contact/update",
};

export default URLs;
