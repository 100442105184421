import React, { Component } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { Card, Table, Button, Modal, Input, message } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import URLs from "../utils/apis";
import { BASE_URL } from "../utils/consts";

const { TextArea } = Input;

class Categories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      tableloader: false,
      addCsrModalStatus: false,
      updateCategoryModalStatus: false,
      records: [],
      formLoader: false,
      edit_category_id: "",
      deleteCsrModalStatus: false,
      edit_category_name: "",
      category_name: "",
    };
  }
  componentDidMount() {
    this.get_category_list();
  }
  get_category_list = () => {
    this.setState({ tableloader: true });
    axios
      .get(URLs.get_category_list)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState({
            records: response.data.data,
            tableloader: false,
          });
        } else {
          this.setState({ tableloader: false });
        }
      })
      .catch((error) => {
        this.setState({ tableloader: false });
        console.error("Warning:", error);
      });
  };
  openAddModal = () => {
    this.setState({
      addCsrModalStatus: true,
    });
  };
  openEditModal = (item) => {
    this.setState({
      updateCategoryModalStatus: true,
      edit_category_id: item.category_id,
      edit_category_name: item.name,
    });
  };
  openDeleteModal = (item) => {
    this.setState({
      deleteCsrModalStatus: true,
      edit_category_id: item.category_id,
    });
  };
  saveCategory = () => {
    if (!this.state.category_name) {
      message.warning("Category name is required");
      return;
    }
    this.setState({ formLoader: false });
    let params = {
      name: this.state.category_name,
    };
    axios
      .post(URLs.add_new_category, params)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState(
            {
              formLoader: false,
              category_name: "",
              addCsrModalStatus: false,
            },
            () => this.get_category_list()
          );
          message.success(response.data.message);
          console.log("response", response.data);
        } else {
          this.setState({ formLoader: false });
          message.warning(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ formLoader: false });
        message.warning("Please try again.");
      });
  };
  updateCategory = () => {
    if (!this.state.edit_category_name) {
      message.warning("Category name is required");
      return;
    }
    this.setState({ formLoader: false });
    let params = {
      id: this.state.edit_category_id,
      name: this.state.edit_category_name,
    };
    axios
      .post(URLs.update_category, params)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState(
            {
              formLoader: false,
              updateCategoryModalStatus: false,
              edit_category_name: "",
              edit_category_id: "",
            },
            () => this.get_category_list()
          );
          message.success(response.data.message);
        } else {
          this.setState({ formLoader: false });
          message.warning(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ formLoader: false });
        message.warning("Please try again.");
      });
  };
  deleteClient = () => {
    let params = {
      category_id: this.state.edit_category_id,
    };
    axios
      .post(URLs.delete_category, params)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState(
            {
              formLoader: false,
              deleteCsrModalStatus: false,
              edit_category_id: "",
            },
            () => this.get_category_list()
          );
          message.success(response.data.message);
        } else {
          this.setState({ formLoader: false });
          message.warning(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ formLoader: false });
        message.warning("Please try again.");
      });
  };
  render() {
    const dataSource =
      this.state.records &&
      this.state.records.map((item, index) => {
        return {
          key: "1",
          sr_no: Number(index + 1),
          category_name: item.name ? item.name : "---",
          action: item,
        };
      });
    const columns = [
      {
        title: "Sr No",
        dataIndex: "sr_no",
        key: "sr_no",
        width: 120,
      },
      {
        title: "Category Name",
        dataIndex: "category_name",
        key: "category_name",
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        render: (item) => {
          return (
            <div>
              <Button
                type="primary"
                className="success"
                onClick={() => this.openEditModal(item)}
              >
                Edit
              </Button>
              <Button
                type="primary"
                danger
                style={{ marginLeft: 10 }}
                onClick={() => this.openDeleteModal(item)}
              >
                Delete
              </Button>
            </div>
          );
        },
      },
    ];
    return (
      <div>
        <Header />
        <div className="content-wrapper">
          <div className="d-flex justify-content-between">
            <Sidebar />
            <div className="main-wrapper">
              <div className="inner">
                <div className="sidebar-space"></div>
                <div className="main-content">
                  <div className="row">
                    <div className="col-lg-12">
                      <Card>
                        <Card>
                          <div className="d-flex justify-content-between">
                            <h3>
                              Category List - (Total:{" "}
                              {this.state.records.length})
                            </h3>
                            <Button
                              type="primary"
                              icon={<PlusCircleOutlined />}
                              onClick={() => this.openAddModal()}
                            >
                              Add New Category
                            </Button>
                          </div>
                        </Card>
                        <br />
                        <Card>
                          <Table
                            loading={this.state.tableloader}
                            dataSource={dataSource}
                            columns={columns}
                          />
                        </Card>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Add modal  */}
        <Modal
          visible={this.state.addCsrModalStatus}
          title="Add New Category"
          okText="Save"
          cancelText="Cancel"
          onOk={() => this.saveCategory()}
          onCancel={() => this.setState({ addCsrModalStatus: false })}
        >
          <div className="mb-5 mt-5">
            <label style={{ fontSize: 16, paddingBottom: 6 }}>
              Category Name
            </label>
            <Input
              type="text"
              value={this.state.category_name}
              onChange={(e) => this.setState({ category_name: e.target.value })}
            />
          </div>
        </Modal>

        {/* Edit modal  */}
        <Modal
          visible={this.state.updateCategoryModalStatus}
          title="Edit Category"
          okText="Update"
          cancelText="Cancel"
          onOk={() => this.updateCategory()}
          onCancel={() => {
            this.setState({
              updateCategoryModalStatus: false,
              edit_category_id: "",
              edit_category_name: "",
            });
          }}
        >
          <div className="mb-5 mt-5">
            <label style={{ fontSize: 16, paddingBottom: 6 }}>
              Category Name
            </label>
            <Input
              type="text"
              value={this.state.edit_category_name}
              onChange={(e) =>
                this.setState({ edit_category_name: e.target.value })
              }
            />
          </div>
        </Modal>

        {/* Delete modal  */}
        <Modal
          visible={this.state.deleteCsrModalStatus}
          title="Delete Category"
          okText="Delete"
          cancelText="Cancel"
          onOk={() => this.deleteClient()}
          onCancel={() => this.setState({ deleteCsrModalStatus: false })}
        >
          <div className="mb-2 mt-4">
            <label style={{ fontSize: 18, paddingBottom: 6 }}>
              Are you sure you want to delete ?
            </label>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Categories;
