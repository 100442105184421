import React, { Component } from "react";
import { Card, Button, Spin, message } from "antd";
import FullEditor from "ckeditor5-build-full";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { LogoutOutlined } from "@ant-design/icons";
import URLs from "../../utils/apis";
import axios from "axios";

export default class AdsContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: "",
      loading: false,
    };
  }
  componentDidMount() {
    this.get_header_data();
  }
  get_header_data = () => {
    this.setState({ loading: true });
    axios
      .get(URLs.get_header_data)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState({
            content: response.data.data[0].ads_content,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        // console.error("Warning:", error);
      });
  };
  update_data = () => {
    let formdata = new FormData();
    formdata.append("ads_content", this.state.content);
    formdata.append("type", "ads_content");
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    axios
      .post(URLs.update_header_data, formdata, config)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState(
            {
              loading: false,
            },
            () => this.get_header_data()
          );
          message.success(response.data.message);
          console.log("response", response.data);
        } else {
          this.setState({ loading: false });
          message.warning(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        message.warning("Please try again.");
        console.error("Warning:", error);
      });
  };
  render() {
    return (
      <div>
        <Spin spinning={this.state.loading}>
          <Card className="ads-content-wrapper">
            <label style={{ fontSize: 16, paddingBottom: 6 }}>
              Ads Contents
            </label>
            {this.state.content && (
              <CKEditor
                data={this.state.content}
                editor={FullEditor}
                //   config={{
                //     ckfinder: {
                //       uploadUrl: Urls.base_url + "" + UPLOAD_IMAGE_URL,
                //     },
                //   }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  this.setState({ content: data });
                }}
              />
            )}
            <br />
            <Button
              type="primary"
              icon={<LogoutOutlined />}
              onClick={() => this.update_data()}
            >
              Update Ads Content
            </Button>
          </Card>
        </Spin>
      </div>
    );
  }
}
