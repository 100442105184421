import React, { Component } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { Card, Table, Button, Modal, Input, message,Select } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import URLs from "../utils/apis";
import { BASE_URL } from "../utils/consts";

const { TextArea } = Input;
const {Option}=Select;

class Citites extends Component {
  constructor(props) {
    super(props);
    this.state = {
      state_list:[],
      list: [],
      description: "",
      tableloader: false,
      addCsrModalStatus: false,
      updateCityModalStatus: false,
      csr_img_url: "",
      client_file_name: "",
      edit_client_file_name: "",
      records: [],
      formLoader: false,
      edlt_client_id: "",
      deleteCsrModalStatus: false,
      edit_description: "",
      edit_csr_image: "",

      state_name: "",
      edit_state_name: "",

      city_name:"",
      state_id:"",
      edit_city_name:"",
      edit_state_id:"",
      city_id:"",
    };
  }
  componentDidMount() {
    this.get_state_list();
    this.get_city_list();
  }
  get_city_list = () => {
    this.setState({ tableloader: true });
    axios
      .get(URLs.get_city_list)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState({
            records: response.data.data,
            tableloader: false,
          });
        } else {
          this.setState({ tableloader: false });
        }
      })
      .catch((error) => {
        this.setState({ tableloader: false });
        console.error("Warning:", error);
      });
  };
  get_state_list = () => {
    this.setState({ tableloader: true });
    axios
      .get(URLs.get_state_list)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState({
            state_list: response.data.data,
            tableloader: false,
          });
        } else {
          this.setState({ tableloader: false });
        }
      })
      .catch((error) => {
        this.setState({ tableloader: false });
        console.error("Warning:", error);
      });
  };
  openAddModal = () => {
    this.setState({
      addCsrModalStatus: true,
      city_name:"",
      state_id:"",
      city_id:""
    });
  };
  openEditModal = (item) => {
    this.setState({
      updateCityModalStatus: true,
      edit_city_name:item.city_name,
      edit_state_id:item.state_id,
      city_id:item.city_id,
    });
  };
  openDeleteModal = (item) => {
    this.setState({
      deleteCsrModalStatus: true,
      city_id:item.city_id,
    });
  };
  saveCity = () => {
    if (!this.state.city_name) {
      message.warning("Please enter city name");
      return;
    }
    if (!this.state.state_id) {
      message.warning("Please select state");
      return;
    }
    this.setState({ formLoader: false });
    let params = {
      name: this.state.city_name,
      state_id: this.state.state_id,
    };
    axios
      .post(URLs.add_new_city, params)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState(
            {
              formLoader: false,
              edit_city_name: "",
              edit_state_id: "",
              addCsrModalStatus: false,
            },
            () => this.get_city_list()
          );
          message.success(response.data.message);
        } else {
          this.setState({ formLoader: false });
          message.warning(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ formLoader: false });
        message.warning("Please try again.");
      });
  };
  updateCity = () => {
    if (!this.state.edit_city_name) {
     message.warning("Please enter city name");
      return;
    }
    if (!this.state.edit_state_id) {
      message.warning("Please select state");
      return;
    }
    this.setState({ formLoader: false });
    let params = {
      name: this.state.edit_city_name,
      state_id: this.state.edit_state_id,
      city_id: this.state.city_id
    };    axios
      .post(URLs.update_city, params)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState(
            {
              formLoader: false,
              edit_city_name: "",
              edit_state_id: "",
              city_id: "",
              updateCityModalStatus: false,
            },
            () => this.get_city_list()
          );
          message.success(response.data.message);
          console.log("response", response.data);
        } else {
          this.setState({ formLoader: false });
          message.warning(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ formLoader: false });
        message.warning("Please try again.");
      });
  };
  deleteClient = () => {
    let params = {
      id: this.state.city_id,
    };
    axios
      .post(URLs.delete_city, params)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState(
            {
              formLoader: false,
              deleteCsrModalStatus: false,
              city_id: "",
            },
            () => this.get_city_list()
          );
          message.success(response.data.message);
        } else {
          this.setState({ formLoader: false });
          message.warning(response.data.message);
        }
      })
      .catch((error) => {
        this.setState({ formLoader: false });
        message.warning("Please try again.");
      });
  };
  render() {
    const dataSource =
      this.state.records &&
      this.state.records.map((item, index) => {
        return {
          key: "1",
          sr_no: Number(index + 1),
          c_name: item.city_name ? item.city_name : "---",
          s_name: item.state_name ? item.state_name : "---",
          action: item,
        };
      });
    const columns = [
      {
        title: "Sr No",
        dataIndex: "sr_no",
        key: "sr_no",
        width: 70,
      },
      {
        title: "City Name",
        dataIndex: "c_name",
        key: "c_name",
      },
      {
        title: "State Name",
        dataIndex: "s_name",
        key: "s_name",
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        render: (item) => {
          return (
            <div>
              <Button
                type="primary"
                className="success"
                onClick={() => this.openEditModal(item)}
              >
                Edit
              </Button>
              <Button
                type="primary"
                danger
                style={{ marginLeft: 10 }}
                onClick={() => this.openDeleteModal(item)}
              >
                Delete
              </Button>
            </div>
          );
        },
      },
    ];
    return (
      <div>
        <Header />
        <div className="content-wrapper">
          <div className="d-flex justify-content-between">
            <Sidebar />
            <div className="main-wrapper">
              <div className="inner">
                <div className="sidebar-space"></div>
                <div className="main-content">
                  <div className="row">
                    <div className="col-lg-12">
                      <Card>
                        <Card>
                          <div className="d-flex justify-content-between">
                            <h3>
                              City List - (Total: {this.state.records.length})
                            </h3>
                            <Button
                              type="primary"
                              icon={<PlusCircleOutlined />}
                              onClick={() => this.openAddModal()}
                            >
                              Add New City
                            </Button>
                          </div>
                        </Card>
                        <br />
                        <Card>
                          <Table
                            loading={this.state.tableloader}
                            dataSource={dataSource}
                            columns={columns}
                          />
                        </Card>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Add modal  */}
        <Modal
          visible={this.state.addCsrModalStatus}
          title="Add New City"
          okText="Save"
          cancelText="Cancel"
          onOk={() => this.saveCity()}
          onCancel={() => this.setState({ addCsrModalStatus: false })}
        >
          <div className="mb-3 mt-3">
            <label style={{ fontSize: 16, paddingBottom: 6 }}>City Name</label>
            <Input
              type="text"
              value={this.state.city_name}
              onChange={(e) => this.setState({ city_name: e.target.value })}
            />
          </div>
          <div className="mb-3">
            <label style={{ fontSize: 16, paddingBottom: 6 }}>State Name</label>
            <Select
              value={this.state.state_id}
              style={{width:'100%'}}
              onChange={(e) => this.setState({ state_id: e })}
            >
              <Option value="">Select</Option>
              {this.state.state_list && this.state.state_list.map((item,index)=>{
                return (
                  <Option value={item.state_id} key={index}>{item.state_name}</Option>
                )
              })}
            </Select>
          </div>
        </Modal>

        {/* Edit modal  */}
        <Modal
          visible={this.state.updateCityModalStatus}
          title="Edit City"
          okText="Update"
          cancelText="Cancel"
          onOk={() => this.updateCity()}
          onCancel={() => this.setState({ updateCityModalStatus: false })}
        >
          <div className="mb-3 mt-3">
            <label style={{ fontSize: 16, paddingBottom: 6 }}>City Name</label>
            <Input
              type="text"
              value={this.state.edit_city_name}
              onChange={(e) => this.setState({ edit_city_name: e.target.value })}
            />
          </div>
          <div className="mb-3">
            <label style={{ fontSize: 16, paddingBottom: 6 }}>State Name</label>
            <Select
              value={this.state.edit_state_id}
              style={{width:'100%'}}
              onChange={(e) => this.setState({ edit_state_id: e })}
            >
              <Option value="">Select</Option>
              {this.state.state_list && this.state.state_list.map((item,index)=>{
                return (
                  <Option value={item.state_id} key={index}>{item.state_name}</Option>
                )
              })}
            </Select>
          </div>
        </Modal>

        {/* Delete modal  */}
        <Modal
          visible={this.state.deleteCsrModalStatus}
          title="Delete City"
          okText="Delete"
          cancelText="Cancel"
          onOk={() => this.deleteClient()}
          onCancel={() => this.setState({ deleteCsrModalStatus: false })}
        >
          <div className="mb-2 mt-4">
            <label style={{ fontSize: 18, paddingBottom: 6 }}>
              Are you sure you want to delete ?
            </label>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Citites;
