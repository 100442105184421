import React, { Component } from "react";
import { Input, Button, Card, Spin, message } from "antd";
import axios from "axios";
import URLs from "./utils/apis";

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      username: "",
      password: "",
    };
  }
  // check for login
  checkForLogin = () => {
    let { username, password } = this.state;
    if (!username) {
      message.warning("Invalid username");
      return;
    }
    if (!password) {
      message.warning("Invalid password.");
      return;
    }
    let params = {
      username: username,
      password,
    };
    this.setState({ loading: true });
    axios
      .post(URLs.login, params)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          localStorage.setItem("admin_id", response.data.data[0].id);
          localStorage.setItem("admin_name", response.data.data[0].username);
          this.setState({ loading: false });
          window.location.assign("/dashboard");
          return;
        } else {
          this.setState({ loading: false });
          message.warning(response.data.message);
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        message.warning("Please try again.");
      });
  };
  forgotPass = () => {
    this.setState({ loading: true });
    axios
      .get(URLs.forgot_password)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState({ loading: false });
          return;
        } else {
          this.setState({ loading: false });
          message.warning(response.data.message);
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };
  // on change input
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  render() {
    const forgotPassStyle = {
      color: "#1677ff",
      textDecoration: "none",
    };
    return (
      <div className="login-container">
        <Spin spinning={this.state.loading}>
          <div className="container">
            <div className="row">
              <div className="col-lg-4 m-auto">
                <Card>
                  <div>
                    <img
                      src="./assets/images/bluechipjobs.jpg"
                      alt="bluchip jobs logo"
                      className="logo"
                      style={{
                        maxWidth: 109,
                        marginTop: 20,
                      }}
                    />
                    <h2 style={{ textAlign: "center" }}>
                      <b>Login</b>
                    </h2>
                    <div className="form-group">
                      <label>Username</label>
                      <br />
                      <Input
                        type="text"
                        onChange={this.handleChange}
                        value={this.state.username}
                        name="username"
                      />
                    </div>
                    <div className="form-group">
                      <label>Password</label>
                      <br />
                      <Input
                        type="password"
                        onChange={this.handleChange}
                        value={this.state.password}
                        name="password"
                      />
                    </div>
                    <div className="form-group d-flex justify-content-end">
                      <a
                        href="#!"
                        style={forgotPassStyle}
                        onClick={() => this.forgotPass()}
                      >
                        Forgot Password
                      </a>
                    </div>
                    <Button
                      onClick={() => this.checkForLogin()}
                      type="primary"
                      block
                    >
                      Login
                    </Button>
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}
